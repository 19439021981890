import * as React from "react";

export type SvgChevronArrowProps = React.SVGProps<SVGSVGElement> & {
  arrowDirection?: "left" | "right" | "down" | "up";
};

function SvgChevronArrow({ arrowDirection, ...props }: SvgChevronArrowProps) {
  const transform = React.useMemo(() => {
    switch (arrowDirection) {
      case "up":
        return "rotate(90deg)";
      case "right":
        return "rotate(180deg)";
      case "down":
        return "rotate(270deg)";
      default:
      case "left":
        return "";
    }
  }, [arrowDirection]);

  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 13 22"
      fill="none"
      // Set transform through style attribute as well for browser compatibility, namely to accommodate Safari.
      // The svg `transform` attribute is part of the SVG2 spec, which has not been implemented by all browsers.
      // https://www.w3.org/TR/SVG2/struct.html#SVGElement
      style={{ transform }}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M.441 11.04c0 .41.153.76.48 1.066l9.118 8.93c.258.257.586.398.973.398.773 0 1.394-.61 1.394-1.395a1.43 1.43 0 00-.422-.996L3.77 11.039l8.214-8.004c.258-.27.422-.62.422-.996 0-.785-.62-1.394-1.394-1.394-.387 0-.715.14-.973.398L.922 9.961c-.328.316-.48.668-.48 1.078z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgChevronArrow;

//Aliases for all Directions

export const ChevronLeft = (props: React.SVGProps<SVGSVGElement>) => {
  return <SvgChevronArrow arrowDirection="left" {...props} />;
};

export const ChevronDown = (props: React.SVGProps<SVGSVGElement>) => {
  return <SvgChevronArrow arrowDirection="down" {...props} />;
};

export const ChevronUp = (props: React.SVGProps<SVGSVGElement>) => {
  return <SvgChevronArrow arrowDirection="up" {...props} />;
};

export const ChevronRight = (props: React.SVGProps<SVGSVGElement>) => {
  return <SvgChevronArrow arrowDirection="right" {...props} />;
};
