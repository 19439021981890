/**
 * Sanitize urls passed to core by stripping the pathname to only
 * keep the last path segment.
 *
 * Since the hosted web authenticator is not served from the origin,
 * we need to strip down the url, so the HandleUrl function in core
 * can match "the last path". This new pathname determines which
 * workflow is executed.
 */
export const sanitizeURL = (rawURL: string) => {
  try {
    const url = new URL(rawURL);

    // Extract the last segment of the pathname
    const segments = url.pathname
      .split("/")
      .filter((segment) => segment !== "");
    const lastSegment = segments[segments.length - 1];

    // Check that the pathname has more than 1 segment.
    // Replace the pathname with the last segment
    // if it is defined, e.g. the original pathname
    // is not "" or "/".
    if (segments.length > 1 && lastSegment) {
      url.pathname = lastSegment;
      return url.toString();
    }
    // Else, return the original url
    return rawURL;
  } catch {
    // if rawURL is not a valid URL,
    // return the original url
    return rawURL;
  }
};
