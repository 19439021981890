import { useNotificationContext } from "context";
import { useCoreMutation } from "hooks";

export const useDeleteCredential = (
  {
    credentialId,
    closeDialog,
  }: {
    credentialId: string;
    closeDialog: () => void;
  },
  version = process.env.REACT_APP_BUILD_TARGET
) => {
  const { showNotification } = useNotificationContext();

  const { mutateAsync: deleteCredential, isLoading: isDeleting } =
    useCoreMutation({
      mutationKey: version === "v0" ? "deleteCredential" : "deleteCredentialV1",
      additionalMutationKeys: [credentialId],
      queriesToInvalidate:
        version === "v0" ? ["getCredentials"] : ["listCredentials"],
      onSuccess: () => {
        showNotification({
          severity: "success",
          messageKey: "deleteSuccessMessage",
        });
      },
      onError: () => {
        showNotification({
          severity: "error",
          messageKey: "deleteErrorMessage",
        });
      },
      onSettled: () => {
        closeDialog();
      },
    });

  return {
    deleteCredential,
    isDeleting,
  };
};
