import { useCallback, useRef } from "react";

import { CredentialV1 } from "types";

import { AuthenticationProgress, SelectCredentialRef } from "./types";

export const useSelectCredentialCallback = ({
  setProgress,
}: {
  setProgress: (progress: AuthenticationProgress) => void;
}) => {
  const selectCredentialRef = useRef<SelectCredentialRef | null>(null);

  /**
   * Callback function that creates a promise to select a credential when more than 1 credential exists.
   * The `selectCredentialRef` is updated with credentials to display on the "Select Account" page,
   * along with functions to select a credential or cancel credential selection.
   */
  const selectCredentialCallback = useCallback(
    async (credentials: CredentialV1[]): Promise<string | undefined> => {
      switch (credentials?.length || 0) {
        case 0:
          // force cancel authentication and show the error page
          setProgress(AuthenticationProgress.Error);
          return Promise.resolve(undefined);
        case 1:
          // auto-select the only credential
          setProgress(AuthenticationProgress.InProgress);
          return Promise.resolve(credentials[0].id);
        default:
          // display screen to select a credential
          return new Promise((resolve) => {
            selectCredentialRef.current = {
              credentials: credentials.map(
                ({ id, identity: { displayName } }) => ({
                  id,
                  label: displayName,
                })
              ),
              selectCredential: (credentialId: string) => {
                setProgress(AuthenticationProgress.InProgress);
                selectCredentialRef.current = null;
                resolve(credentialId);
              },
              cancel: () => {
                // TODO: CON-782 refine cancel workflow
                selectCredentialRef.current = null;
                resolve(undefined);
              },
            } as SelectCredentialRef;

            setProgress(AuthenticationProgress.SelectAccount);
          });
      }
    },
    [setProgress]
  );

  return { selectCredentialCallback, selectCredentialRef };
};
