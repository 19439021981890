import { useMemo } from "react";

import { useCoreQuery } from "./useCoreQuery";

export enum BrowserDisplayMode {
  Browser = "browser",
  PWA = "pwa",
}

export const useBrowserInfo = () => {
  const { data: browser = "" } = useCoreQuery({
    queryKey: "getBrowserInfo",
    select: (browserInfo) => {
      const { browser, platform } = browserInfo;
      // Firefox on iPads is being reported as "Safari" because
      // the user agent does is identical to the one that Safari uses.
      // Firefox has an open issue regarding the lack of a "FxiOS" identifier:
      // https://github.com/mozilla-mobile/firefox-ios/issues/6620
      //
      // Since we have no way to reliably distinguish between Safari vs. Firefox on iPads,
      // report the browser as an empty string ""/
      if (
        // Check that the device is a touch screen
        window.navigator.maxTouchPoints > 0 &&
        // Check if the current device is running iOS but not reported as such,
        // since iPhones report the platform as "iOS" while iPads report "macOS".
        platform?.name === "Mac OS" &&
        // Check if the browser is being detected as Safari
        browser?.name === "Safari"
      ) {
        return "";
      }

      return browser?.name;
    },
  });

  /**
   * Browser display mode.
   *
   * Check the CSS media display-mode value.
   * If it matches the "display" field that is set in
   * the web manifest, then we are in a PWA context.
   */
  const displayMode = useMemo(
    () =>
      // Use optional chaining against the window object
      // so unit tests don't throw errors.
      window?.matchMedia("(display-mode: standalone)")?.matches
        ? BrowserDisplayMode.PWA
        : BrowserDisplayMode.Browser,
    []
  );

  return { browser, displayMode };
};
