import {
  List,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { BasePage, ChevronLeft, colors } from "design-library";

import { VISIT_SUPPORT_LINK } from "constants/links";
import { PATHS } from "constants/paths";
import { useTranslate } from "i18n";
import { convertPathToHREF } from "utilities";

import { SelectCredentialRef } from "./types";

const useStyles = makeStyles()((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    height: `calc(100% - ${theme.spacing(8)})`,
    overflow: "hidden",
  },
  main: {
    width: "100%",
    overflow: "hidden",
    margin: "0 auto",
  },
  list: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
    overflowY: "auto",
  },
  listItem: {
    border: `1px solid ${colors["neutral-40"]}`,
    borderRadius: theme.shape.borderRadius,
  },
  listItemText: {
    maxWidth: `calc(100% - ${theme.spacing(2)})`,
  },
  icon: {
    transform: "rotate(180deg)",
    height: "12px",
  },
}));

type SelectAccountPageProps = Partial<SelectCredentialRef>;

export const SelectAccountPage = ({
  credentials = [],
  selectCredential,
}: SelectAccountPageProps) => {
  const { classes } = useStyles();
  const { t } = useTranslate();

  return (
    <BasePage
      classes={{
        container: classes.container,
        main: classes.main,
      }}
      footerLinks={[
        {
          promptText: t("wantToManageCredentials"),
          linkText: t("goToAuthenticator"),
          LinkProps: { href: convertPathToHREF(PATHS.INDEX), target: "_blank" },
        },
        {
          promptText: t("questionOrIssues"),
          linkText: t("visitSupport"),
          LinkProps: { href: VISIT_SUPPORT_LINK, target: "_blank" },
        },
      ]}
    >
      <Typography
        color="textPrimary"
        component="h1"
        variant="headerMediumRegular"
      >
        {t("selectAccount")}
      </Typography>
      <List disablePadding className={classes.list}>
        {credentials.map(({ id, label }, i) => (
          <ListItemButton
            key={`${id}-${label}-${i}`}
            className={classes.listItem}
            component="li"
            onClick={() => selectCredential?.(id)}
          >
            <ListItemText
              primaryTypographyProps={{
                noWrap: true,
                title: label,
                variant: "body1",
                className: classes.listItemText,
              }}
            >
              {label}
            </ListItemText>
            <ListItemSecondaryAction>
              <ChevronLeft className={classes.icon} />
            </ListItemSecondaryAction>
          </ListItemButton>
        ))}
      </List>
    </BasePage>
  );
};
