import {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useState,
} from "react";
import { SnackbarCloseReason } from "@mui/material";
import { ToastNotification, ToastNotificationProps } from "design-library";

import { TranslationKey, useTranslate } from "i18n";

type NotificationType =
  | ({
      messageKey: TranslationKey;
    } & Omit<ToastNotificationProps, "children">)
  | null;

export const NotificationContext = createContext<{
  notification: NotificationType;
  showNotification: (notification: NotificationType) => void;
}>({
  notification: null,
  showNotification: () => {},
});

export const NotificationContextProvider: FC<
  PropsWithChildren<{
    notification?: NotificationType;
  }>
> = ({ children, notification: notificationProp = null }) => {
  const { t } = useTranslate();
  const [notification, showNotification] =
    useState<NotificationType>(notificationProp);

  const {
    messageKey,
    onClose: onCloseProp,
    severity,
    ...notificationProps
  } = notification ?? {};

  const onClose = useCallback(
    (
      e: Event | React.SyntheticEvent<Element, Event>,
      type: SnackbarCloseReason
    ) => {
      showNotification(null);
      onCloseProp?.(e, type);
    },
    [onCloseProp]
  );

  // eslint-disable-next-line
  // @ts-ignore TypeScript does not recognize equal string unions even though both are typed `TranslationKey`
  const message = t(messageKey);

  return (
    <NotificationContext.Provider value={{ notification, showNotification }}>
      {children}
      {notification && (
        <ToastNotification
          open
          autoHideDuration={3000}
          dismissText={t("dismiss")}
          // TODO: do not show "Report Error" button until we define a report error handler
          severity={severity ?? "success"}
          showReportError={false}
          {...notificationProps}
          {...{ onClose }}
        >
          {message}
        </ToastNotification>
      )}
    </NotificationContext.Provider>
  );
};

export const useNotificationContext = () => {
  const context = useContext(NotificationContext);

  if (context === undefined) {
    throw new Error(
      "useNotificationContext must be used within a NotificationContextProvider"
    );
  }
  return context;
};
