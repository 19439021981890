import * as React from "react";

function SvgTimesCircleErrorIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10 .313A9.686 9.686 0 00.312 10 9.686 9.686 0 0010 19.688 9.686 9.686 0 0019.688 10 9.686 9.686 0 0010 .312zm0 18.125c-4.637 0-8.438-3.754-8.438-8.438 0-4.637 3.754-8.438 8.438-8.438 4.637 0 8.438 3.754 8.438 8.438 0 4.637-3.754 8.438-8.438 8.438zm3.703-11.145L10.996 10l2.707 2.707a.47.47 0 010 .664l-.332.332a.47.47 0 01-.664 0L10 10.996l-2.707 2.707a.47.47 0 01-.664 0l-.332-.332a.47.47 0 010-.664L9.004 10 6.297 7.293a.47.47 0 010-.664l.332-.332a.47.47 0 01.664 0L10 9.004l2.707-2.707a.47.47 0 01.664 0l.332.332c.18.184.18.48 0 .664z"
        fill="#D32222"
      />
    </svg>
  );
}

export default SvgTimesCircleErrorIcon;
