export const PATHS = {
  INDEX: "/",
  /** v0 route */
  AUTHORIZE: "/authorize",
  /** v1 route */
  BI_AUTHENTICATE: "/bi-authenticate",
  /** v1 route */
  BIND: "/bind",
  /** v0 route */
  IMPORT: "/import",
  /** v0 route */
  REGISTER: "/register",
};
