/**
 * This function is used to clean up error messages that come in
 * from the rust core, and format and encode them.
 * Examples:
 * - Embedded(Client(Client(Response(400, "Invalid OIDC request: unknown client_id\n"))))
 * 	=> "400 Invalid OIDC request: unknown client_id"
 * - Some(Other(Example)) => Example
 * - Not a rust error => Not a rust error
 */
export const parseErrorMessage = (msg: string): string => {
  // RegExp to parse out inner most content of parenthesis
  // -- Examples:
  // Some(Random(Error(Message("contents of error message")))) => "("contents of error message")"
  // Some(Other(Example)) => (Example)
  const reg = /\(([^()"]*"[^"]*")+[^()"]*\)|\([^()]*\)/;
  const matchedResult = msg.match(reg)?.shift();

  // If we don't get a match, return the original error message
  if (matchedResult === null || matchedResult === undefined) {
    return msg;
  }

  // Remove error code and extract only the error message
  const error = matchedResult.split(",").pop()?.trim() || "";

  // Clean up the error message
  const cleanedUp = error
    .replace(/\\n/g, "") // Remove all newlines
    .replace(/"\{/g, "{") // Escape left bracket
    .replace(/\}"/g, "}") // Escape right bracket
    .replace(/\\"/g, '"') // Escape quotes
    .replace(/\(/g, "") // Remove remaining left parethesis
    .replace(/\)/g, "") // Remove last remaining right parenthesis
    .replace(/\s+/g, " ") // Removes extra spaces
    .replace(/^"/g, "") // Remove start double quote
    .replace(/"$/g, "") // Remove end double quote
    .trim(); // Removes outer spaces

  // Custom error messages
  let errorMessage = cleanedUp;
  if (cleanedUp.includes("CredentialNotFound")) {
    errorMessage = "CredentialNotFound";
  }

  // Encode the final string
  return encodeURIComponent(errorMessage);
};
