import { ProgressPage } from "design-library";

import { ErrorPage } from "components";
import { useTranslate } from "i18n";

import { useRegisterCredential } from "./useRegisterCredential";

export const SetUpCredentialScreen = () => {
  const { t } = useTranslate();

  const { updatesURI, isError } = useRegisterCredential();

  if (!updatesURI) {
    return <ErrorPage />;
  }
  if (isError) {
    return (
      <ErrorPage showRetryInstructions message={t("couldNotSetupCredential")} />
    );
  }
  return <ProgressPage message={t("settingUpYourCredential")} />;
};
