import { Core } from "coresdk";

import { useCoreContext } from "context";
import { mockCore } from "mocks";

/**
 * Custom hook that returns the core object.
 * - Access core via this hook so that it may be mocked for unit testing.
 */
export const useCore = (): Core | undefined => {
  const { core } = useCoreContext();
  return process.env.REACT_APP_USE_MOCK_CORE ? mockCore : core;
};
