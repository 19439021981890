import { Toolbar as MUIToolbar, ToolbarProps } from "@mui/material";
import { makeStyles } from "tss-react/mui";

export const DESKTOP_TOOLBAR_HEIGHT = 96;

const useStyles = makeStyles()((theme) => ({
  toolbar: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
      height: DESKTOP_TOOLBAR_HEIGHT,
      justifyContent: "center",
    },
  },
}));

/**
 * UI component used to help position elements
 * below the AppBar in desktop view.
 * */
export const Toolbar = ({ children }: ToolbarProps) => {
  const { classes } = useStyles();

  return <MUIToolbar className={classes.toolbar}>{children}</MUIToolbar>;
};
