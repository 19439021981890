export const elevationStyles = {
  "elevation-style-1": {
    boxShadow:
      "0px 11px 15px 0px rgba(0, 0, 0, 0.2), 0px 9px 46px 0px rgba(0, 0, 0, 0.12), 0px 24px 38px 0px rgba(0, 0, 0, 0.14)",
  },
  "elevation-style-2": {
    boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.15)",
  },
  "elevation-style-3-left": {
    boxShadow: "4px 0px 4px 0px rgba(0, 0, 0, 0.04)",
  },
  "elevation-style-3-right": {
    boxShadow: "-4px 0px 4px 0px rgba(0, 0, 0, 0.04)",
  },
};
