/* eslint-disable */
// @ts-nocheck : skip type check for testing mocks

import { Core, CredentialV1 } from "coresdk";

import {
  BindCredentialV1Result,
  Credential,
  AuthorizationCode,
  TrustedSource,
  UrlResponse,
  PkceCodeChallenge,
} from "types";

/** Mock data that would be returned from core endpoints */
export const mockDispatchData = {
  appInstanceId: "49B6170C-C3FD-4F9C-B894-5CB478FCC844",
  getCredentials: [
    <Credential>{
      state: "Active",
      created: "1995-01-01T00:00:00",
      handle: "byndid-rolling",
      keyHandle: "byndid-rolling",
      name: "Beyond Identity Rolling",
      imageURL:
        "https://app.rolling.byndid.run/static/img/logo_transparent.0e2f3a725bf7cea4cbe7.png",
      loginURI: "https://app.rolling.byndid.run/downloads",
      enrollURI: "https://app.rolling.byndid.run/downloads",
      chain: ["1", "2"],
      rootFingerprint: "asdfdsadsadsdaklmfiieongoinlkfn3ppj",
    },
    <Credential>{
      state: "Active",
      created: "2021-11-08T20:08:23.369Z",
      handle: "directory-devel",
      keyHandle: "km:809f9d03a155d8c7",
      name: "Directory Devel",
      imageURL:
        "https://byndid-public-assets.s3-us-west-2.amazonaws.com/logos/beyondidentity.png",
      loginURI: "",
      enrollURI: "",
      chain: [
        "-----BEGIN CERTIFICATE-----\r\nMIIBljCCAT2gAwIBAgIIYhl5dH0gYl8wCgYIKoZIzj0EAwIwHzEdMBsGA1UEAwwU\r\naW50OiBkaXJlY3RvcnktZGV2ZWwwHhcNMjExMTA4MTg1ODIzWhcNMzExMDI3MjAw\r\nODIzWjAfMR0wGwYDVQQDDBRpbnQ6IGRpcmVjdG9yeS1kZXZlbDBZMBMGByqGSM49\r\nAgEGCCqGSM49AwEHA0IABFoSJ795un9SVQUXFcRLyMbhI0LcZZ4GsH2QztJcfOYh\r\nA6TrPQZlqhg94V94QbOqwvvxnEdXKuilHugZDhZh29ujYzBhMA4GA1UdDwEB/wQE\r\nAwICpDAPBgNVHRMBAf8EBTADAQH/MB8GA1UdIwQYMBaAFK27M1Nm8yUXqN4AcEgl\r\n/r9VZ+FTMB0GA1UdDgQWBBT1IxN87L7zwTNI5Kvi1/L1DcteBzAKBggqhkjOPQQD\r\nAgNHADBEAiAz+/RbRbXEDbzYXtxWHLi12D1f7n0IfqYKvMgnxYykDQIgTJXSXvqy\r\nd4E26/TZcV1woLHMvl3q3kMmIiK+2FuLP+c=\r\n-----END CERTIFICATE-----\r\n",
        "-----BEGIN CERTIFICATE-----\r\nMIIBmTCCAT6gAwIBAgIITokMHiBL+5gwCgYIKoZIzj0EAwIwIDEeMBwGA1UEAwwV\r\ncm9vdDogZGlyZWN0b3J5LWRldmVsMB4XDTIxMTEwNjIyMDkyOVoXDTMxMTAyNTIz\r\nMTkyOVowHzEdMBsGA1UEAwwUaW50OiBkaXJlY3RvcnktZGV2ZWwwWTATBgcqhkjO\r\nPQIBBggqhkjOPQMBBwNCAAQywt+cbrLkS/pH8vwZjfn8CkVMJ5SX6kk0VdHLPKBk\r\nacCUSaxDnP2b+s3rh23qIwty0qqHcgRkR2ihP7rsWSPbo2MwYTAOBgNVHQ8BAf8E\r\nBAMCAqQwDwYDVR0TAQH/BAUwAwEB/zAfBgNVHSMEGDAWgBRnfa+UFE9a7udlX04w\r\nfCNQdSCNMjAdBgNVHQ4EFgQUrbszU2bzJReo3gBwSCX+v1Vn4VMwCgYIKoZIzj0E\r\nAwIDSQAwRgIhALHmaoSqL/xUAs9kWV1/KoTNjkeapgnvwxLkm2AcIFOBAiEAhkEO\r\nIhsz1+EY/TX0Uk0VxC5N6znnxq4QlcXacl+ksyU=\r\n-----END CERTIFICATE-----\r\n",
        "-----BEGIN CERTIFICATE-----\r\nMIIBeDCCAR6gAwIBAgIIGn3WgK/8BLAwCgYIKoZIzj0EAwIwIDEeMBwGA1UEAwwV\r\ncm9vdDogZGlyZWN0b3J5LWRldmVsMB4XDTIxMTEwNjIyMDkyOVoXDTMxMTAyNTIz\r\nMTkyOVowIDEeMBwGA1UEAwwVcm9vdDogZGlyZWN0b3J5LWRldmVsMFkwEwYHKoZI\r\nzj0CAQYIKoZIzj0DAQcDQgAELc3sHEC3pgX1nAWQ6rXuRMvEHoqmwN5v4nyZEHPO\r\ndQ3BfZGyKwxMDhQPYYl/zAHB+IXuR7zAOTRdVgpkGExbLKNCMEAwDgYDVR0PAQH/\r\nBAQDAgKkMA8GA1UdEwEB/wQFMAMBAf8wHQYDVR0OBBYEFGd9r5QUT1ru52VfTjB8\r\nI1B1II0yMAoGCCqGSM49BAMCA0gAMEUCIQDtDJ4IRsPMY9D2mX1oCPsL9JyTyZ2G\r\nGZAdCjtZwOXTaQIgHIndKUAZ+LsJ7HWgmw/wjHC2FiLNxD88DbjCdvoXQWU=\r\n-----END CERTIFICATE-----\r\n",
      ],
      rootFingerprint:
        "c4eed405f71f6dd42c3b19ffa18b8d3c0ca57dfa7f1d4c3c9e5c542562c7a8f2",
    },
    <Credential>{
      state: "Active",
      created: "2021-11-08T20:10:45.653Z",
      handle: "web-authenticator-devel",
      keyHandle: "km:802efb9b09b1c6de",
      name: "Web Authenticator Test",
      imageURL: "https://placekitten.com/g/400/400",
      loginURI: "",
      enrollURI: "",
      chain: [
        "-----BEGIN CERTIFICATE-----\r\nMIIBqTCCAU6gAwIBAgIINTqiadGaTdgwCgYIKoZIzj0EAwIwKDEmMCQGA1UEAwwd\r\ncm9vdDogd2ViLWF1dGhlbnRpY2F0b3ItZGV2ZWwwHhcNMjExMTA4MTkwMDQ2WhcN\r\nMzExMDI3MjAxMDQ2WjAnMSUwIwYDVQQDDBxpbnQ6IHdlYi1hdXRoZW50aWNhdG9y\r\nLWRldmVsMFkwEwYHKoZIzj0CAQYIKoZIzj0DAQcDQgAEM6paYRY2kstVqf+Jc+Jv\r\nmu80SgIUlGyRALTZLugQSbX6dw/kgiZWF3kEEBv4rnNLByzscbZGQuZ0Bipccaom\r\n6KNjMGEwDgYDVR0PAQH/BAQDAgKkMA8GA1UdEwEB/wQFMAMBAf8wHwYDVR0jBBgw\r\nFoAU0mOd5n9zGc9kdlibMcM9tyMg0vQwHQYDVR0OBBYEFGIUhkcLuXfla6+xOdl5\r\nYtfWakuKMAoGCCqGSM49BAMCA0kAMEYCIQDPmcyL7anuepx45Wsr6EqZaXJwL7cQ\r\nswMiF8CqD+yrZwIhAIcH1L9YjBAKlbT4o4SVdccxH06cfilTrjf2Fd+GzJY1\r\n-----END CERTIFICATE-----\r\n",
        "-----BEGIN CERTIFICATE-----\r\nMIIBhzCCAS6gAwIBAgIIalkGABsTohAwCgYIKoZIzj0EAwIwKDEmMCQGA1UEAwwd\r\ncm9vdDogd2ViLWF1dGhlbnRpY2F0b3ItZGV2ZWwwHhcNMjExMTA4MTkwMDQ2WhcN\r\nMzExMDI3MjAxMDQ2WjAoMSYwJAYDVQQDDB1yb290OiB3ZWItYXV0aGVudGljYXRv\r\nci1kZXZlbDBZMBMGByqGSM49AgEGCCqGSM49AwEHA0IABL9WKHO15AtOjZo2mqk2\r\nA33qNJn99TusWZ0eWZN6k6K1sjTd3wdtY+vGuvBidBpnZPzKJm4oMJtmRM+foert\r\netWjQjBAMA4GA1UdDwEB/wQEAwICpDAPBgNVHRMBAf8EBTADAQH/MB0GA1UdDgQW\r\nBBTSY53mf3MZz2R2WJsxwz23IyDS9DAKBggqhkjOPQQDAgNHADBEAiAcsNsBqXfc\r\nrNSGlCVDAaWHWgOd6eNZnY1J8B8/dG1DQAIgEgj6ik5dodQWQhtNf4pzTgHByVc9\r\nJ2uKzYDwaS6sJTA=\r\n-----END CERTIFICATE-----\r\n",
      ],
      rootFingerprint:
        "af50ffc0ecea91486931d78e92f24b05cbf9706cdd55ad8f35806939bbdf8ed7",
    },
  ],
  bindCredential: {
    credential: <CredentialV1>{
      id: "mock_user_credential_id",
      apiBaseUrl: "https://example.com",
      keyHandle: "mock_user_key_handle",
      state: "Active",
      tenant: {
        id: "mock_tenant",
        displayName: "Tenant Display Name",
      },
      realm: {
        id: "mock_realm",
        name: "mock_realm",
        displayName: "Realm Display Name",
      },
      identity: {
        id: "mock_user",
        displayName: "User Display Name",
      },
      theme: {
        logoUrlLight:
          "https://app.rolling.byndid.run/static/img/logo.eada4aebc845fb4514b5.png",
        logoUrlDark:
          "https://app.rolling.byndid.run/static/img/logo.eada4aebc845fb4514b5.png",
        primaryColorLight: "#4673D3",
        primaryColorDark: "#FFFFFF",
        supportUrl: "https://support.beyondidentity.com/",
      },
    },
    postBindRedirect: "http://example.com/",
  },
  listCredentials: [
    <CredentialV1>{
      id: "mock_user_credential_id",
      apiBaseUrl: "https://example.com",
      keyHandle: "mock_user_key_handle",
      created: "2021-11-08T20:10:45.653Z",
      state: "Active",
      tenant: {
        id: "mock_tenant",
        displayName: "Realm Display Name",
      },
      realm: {
        id: "mock_realm",
        name: "mock_realm",
        displayName: "Realm Display Name",
      },
      identity: {
        id: "mock_user",
        emailAddress: "first_user@mock_realm.com",
        displayName: "First User",
      },
      theme: {
        logoUrlLight:
          "https://app.rolling.byndid.run/static/img/logo.eada4aebc845fb4514b5.png",
        logoUrlDark:
          "https://app.rolling.byndid.run/static/img/logo.eada4aebc845fb4514b5.png",
        primaryColorLight: "#4673D3",
        primaryColorDark: "#FFFFFF",
        supportUrl: "https://support.beyondidentity.com/",
      },
    },
    <CredentialV1>{
      apiBaseUrl: "https://example.com",
      id: "mock_user_credential_id_18749473",
      keyHandle: "mock_user_key_handle_2",
      created: "2021-11-08T20:10:45.653Z",
      state: "Active",
      tenant: {
        id: "mock_tenant",
        displayName: "Realm Display Name",
      },
      realm: {
        id: "mock_realm",
        name: "mock_realm",
        displayName: "Realm Display Name",
      },
      identity: {
        id: "mock_user_2",
        emailAddress: "second_user@mock_realm.com",
        displayName: "Second User",
      },
      theme: {
        logoUrlLight:
          "https://app.rolling.byndid.run/static/img/logo.eada4aebc845fb4514b5.png",
        logoUrlDark:
          "https://app.rolling.byndid.run/static/img/logo.eada4aebc845fb4514b5.png",
        primaryColorLight: "#4673D3",
        primaryColorDark: "#FFFFFF",
        supportUrl: "https://support.beyondidentity.com/",
      },
    },
  ] as CredentialV1[],
  authenticate: {
    type: "biAuthenticate",
    biAuthenticate: { redirectURL: "https://example.com/bi-authenticate-done" },
  } as UrlResponse,
};

/**
 * Use timeout and promise to simulate endpoint interaction.
 * */
const mockPromise = <V = unknown>({
  value,
  timeout = process.env.NODE_ENV === "test" ? 0 : 500,
  error,
}: {
  value?: V;
  timeout?: number;
  error?: V;
} = {}): Promise<V> => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (error) {
        reject(error);
      } else {
        resolve(value as V);
      }
    }, timeout);
  });
};

async function getCredentials(): Promise<Credential[]> {
  return mockPromise({ value: mockDispatchData.getCredentials });
}

async function getAppInstanceId() {
  return mockPromise({ value: mockDispatchData.appInstanceId });
}

async function getBrowserInfo() {
  return mockPromise({
    value: { browser: { name: "Chrome", version: "0.0.0" } },
  });
}

async function deleteCredential(handle: string): Promise<void> {
  return mockPromise();
}

async function authenticateConfidential(
  authURL: string,
  clientId: string,
  redirectURI: string,
  scope: string,
  challenge?: PkceCodeChallenge
): Promise<AuthorizationCode> {
  return mockPromise({ value: { code: "SOMECODE" } });
}

async function register(
  url: string,
  trustedSource: TrustedSource
): Promise<UrlResponse> {
  return mockPromise({
    value: {
      type: "registration",
      registration: { credential: mockDispatchData.getCredentials[0] },
    },
    timeout: 1000,
  });
}

async function exportCredential(
  handle: string,
  onExport: (ev) => void
): Promise<string> {
  return mockPromise({ value: "123456789" });
}

async function importCredential(
  token: string,
  address: string,
  onImport?: (ev) => void
): Promise<Credential | undefined> {
  return mockPromise({ value: mockDispatchData.getCredentials[0] });
}

async function cancel() {
  return Promise.resolve();
}

export async function bindCredential(): Promise<BindCredentialV1Result> {
  return mockPromise({ value: mockDispatchData.bindCredential });
}

export async function listCredentials(): Promise<CredentialV1[]> {
  return mockPromise({ value: mockDispatchData.listCredentials });
}

export async function authenticate(
  url,
  trustedSource,
  onSelectCredential
): Promise<UrlResponse> {
  await onSelectCredential(mockDispatchData.listCredentials);
  return mockPromise({ value: mockDispatchData.authenticate });
}

async function deleteCredentialV1(credentialId: string): Promise<void> {
  return mockPromise();
}

/** Mock core endpoints */
export const mockCore: Core = {
  getCredentials,
  deleteCredential,
  authenticateConfidential,
  register,
  export: exportCredential,
  import: importCredential,
  getAppInstanceId,
  getBrowserInfo,
  cancel,
  bindCredential,
  listCredentials,
  authenticate,
  deleteCredentialV1,
};
