import { useEffect, useMemo, useState } from "react";

import { useCoreMutation, useRedirect } from "hooks";
import { BiAuthenticateResponse } from "types";
import { sanitizeURL } from "utilities";

import { AuthenticationProgress } from "./types";
import { useSelectCredentialCallback } from "./useSelectCredentialCallback";

/**
 * Executes an authentication request to the core,
 * provided a `bi_authenticate_uri` exists.
 */
export const useBIAuthenticate = (url: string) => {
  const { redirectToExternalURI } = useRedirect();

  const biAuthenticateURI = useMemo(() => sanitizeURL(url), [url]);

  const [progress, setProgress] = useState(
    biAuthenticateURI
      ? AuthenticationProgress.Started
      : AuthenticationProgress.Error
  );

  const { mutateAsync: authenticate } = useCoreMutation({
    mutationKey: "authenticate",
    additionalMutationKeys: [biAuthenticateURI],
    onSuccess: (res: BiAuthenticateResponse) => {
      if ("allow" in res) {
        setProgress(AuthenticationProgress.Finished);
        redirectToExternalURI(res.allow.redirectURL);
      } else {
        setProgress(AuthenticationProgress.Error);
      }
    },
    onError: () => setProgress(AuthenticationProgress.Error),
  });

  const { selectCredentialCallback, selectCredentialRef } =
    useSelectCredentialCallback({ setProgress });

  useEffect(() => {
    if (biAuthenticateURI && progress === AuthenticationProgress.Started) {
      authenticate([
        biAuthenticateURI,
        { credentialSelect: selectCredentialCallback },
      ]);
    }
  }, [authenticate, biAuthenticateURI, progress, selectCredentialCallback]);

  return {
    progress,
    ...selectCredentialRef.current,
  };
};
