/* eslint-disable tss-unused-classes/unused-classes */
import {
  AppBar as MUIAppBar,
  Box,
  IconButton,
  useMediaQuery,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { makeStyles } from "tss-react/mui";
import { BITextLogo } from "design-library";

import { SettingsMenu } from "./SettingsMenu";
import { Toolbar } from "./Toolbar";

interface AppBarProps {
  onMenuButtonClick: () => void;
  hasCredentials: boolean;
}

const useDesktopStyles = makeStyles()((theme) => ({
  desktopAppBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: theme.palette.common.white,
    display: "flex",
    boxShadow: "none",
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  textLogo: {
    width: 135,
    height: "100%",
  },
  settingsMenu: {
    position: "absolute",
    right: 12,
    top: "50%",
    transform: "translateY(-50%)",
  },
}));

const useMobileStyles = makeStyles<{ hasCredentials: boolean }>()(
  (theme, { hasCredentials }) => ({
    mobileAppBar: {
      boxShadow: "none",
      backgroundColor: "transparent",
    },
    mobileMenuBar: {
      display: "flex",
      justifyContent: "space-between",
      width: "100%",
      backgroundColor: hasCredentials ? theme.palette.divider : "transparent",
    },
    menuIcon: {
      color: theme.palette.text.primary,
    },
  })
);

export const AppBar = ({ onMenuButtonClick, hasCredentials }: AppBarProps) => {
  const { classes: desktopClasses, theme } = useDesktopStyles();
  const { classes: mobileClasses } = useMobileStyles({ hasCredentials });

  const showDesktopView = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <MUIAppBar
      position="fixed"
      className={
        showDesktopView
          ? desktopClasses.desktopAppBar
          : mobileClasses.mobileAppBar
      }
    >
      {showDesktopView ? (
        <Toolbar>
          <BITextLogo className={desktopClasses.textLogo} />
          <div className={desktopClasses.settingsMenu}>
            <SettingsMenu />
          </div>
        </Toolbar>
      ) : (
        <Box className={mobileClasses.mobileMenuBar}>
          <IconButton
            className={mobileClasses.menuIcon}
            disabled={!hasCredentials}
            size="large"
            onClick={onMenuButtonClick}
          >
            {/* Show hamburger menu icon only when there are credentials */}
            {hasCredentials && <MenuIcon />}
          </IconButton>
          <SettingsMenu />
        </Box>
      )}
    </MUIAppBar>
  );
};
