import { ProgressPage } from "design-library";

import { ErrorPage } from "components";
import { useTranslate } from "i18n";

import { SelectAccountPage } from "./SelectAccountPage";
import { AuthenticationProgress } from "./types";
import { useBIAuthenticate } from "./useBIAuthenticate";

export const BIAuthenticateScreen = () => {
  const { t } = useTranslate();
  const { progress, credentials, selectCredential } = useBIAuthenticate(
    window.location.href
  );

  return (
    <>
      {progress === AuthenticationProgress.Started && (
        <ProgressPage
          showLogo
          loaderPosition="bottom"
          message={t("initializingBeyondIdentityDetails")}
          title={t("initializingBeyondIdentity")}
        />
      )}
      {progress === AuthenticationProgress.SelectAccount && (
        <SelectAccountPage {...{ credentials, selectCredential }} />
      )}
      {progress === AuthenticationProgress.InProgress && (
        <ProgressPage
          showLogo
          loaderPosition="bottom"
          message={t("verifyingYourIdentityDetails")}
          title={t("verifyingYourIdentity")}
        />
      )}
      {progress === AuthenticationProgress.Finished && (
        <ProgressPage
          showLogo
          loaderPosition="bottom"
          message={t("identityVerifiedDetails")}
          title={`${t("identityVerified")} 👍`}
        />
      )}
      {progress === AuthenticationProgress.Error && (
        <ErrorPage showRetryInstructions message={t("authenticateError")} />
      )}
    </>
  );
};
