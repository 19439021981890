import {
  Box,
  Dialog,
  DialogProps,
  IconButton,
  Typography,
} from "@mui/material";
import { makeStyles } from "tss-react/mui";
import classnames from "classnames";
import { BILogo, CloseIcon } from "design-library";

import { useTranslate } from "i18n";

const useStyles = makeStyles()((theme) => ({
  dialog: {
    margin: 0,
    padding: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      minWidth: "500px",
    },
  },
  closeButton: {
    margin: theme.spacing(-1.5),
    width: "min-content",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingTop: theme.spacing(2),
    gap: theme.spacing(2),
  },
}));

export const AboutBeyondIdentityPopup = ({
  appInstanceId,
  onClose,
  ...props
}: { appInstanceId?: string } & Omit<DialogProps, "children">) => {
  const { t } = useTranslate();
  const { classes } = useStyles();

  const version = process.env.REACT_APP_VERSION;
  const year = new Date().getFullYear();

  return (
    <Dialog
      classes={{
        ...props.classes,
        paper: classnames(classes.dialog, props.classes?.paper),
      }}
      {...{ onClose }}
      {...props}
    >
      <IconButton
        aria-label={t("close")}
        className={classes.closeButton}
        size="large"
        onClick={(e) => onClose?.(e, "backdropClick")}
      >
        <CloseIcon height={20} width={20} />
      </IconButton>
      <Box className={classes.content}>
        <BILogo height={65} width={65} />
        <Typography component="h2" variant="headerSmallRegular">
          {t("beyondIdentity")}
        </Typography>
        {version && (
          <Typography>{t("appVersionAndBuildNumber", { version })}</Typography>
        )}
        {appInstanceId && <Typography>{appInstanceId}</Typography>}
        <Typography>{t("aboutCopyrightNotice", { year })}</Typography>
      </Box>
    </Dialog>
  );
};
