/* eslint-disable import/no-duplicates */
import { enUS as MUI_enUS, Localization } from "@mui/material/locale";
import { Locale } from "date-fns";
import { enUS as DF_enUS } from "date-fns/locale";

export const defaultLocale = "en-US";

/** List of supported locales. */
export const locales = [defaultLocale];

/**
 * Finds the supported locale.
 * Accepts a locale string or defaults to using the browser's locale.
 * ex. "en-US" => "en-US"
 * ex. "en" => "en-US"
 * */
export const getLocale = (locale?: string) => {
  const initLocale = locale || navigator.language;
  const newLocale = locales.find(
    (l) => initLocale === l || l.includes(initLocale)
  );
  return newLocale || defaultLocale;
};

/**
 * Mapping of BCP 47 language tag to the locale objects exported by Material UI and date-fns:
 * - https://mui.com/guides/localization/#supported-locales
 * - https://date-fns.org/v2.23.0/docs/I18n
 * */
const localeMapping: Record<
  string,
  { "material-ui": Localization; "date-fns": Locale }
> = {
  "en-US": {
    "material-ui": MUI_enUS,
    "date-fns": DF_enUS,
  },
};

const getLocaleInstance = (type: "material-ui" | "date-fns", locale?: string) =>
  localeMapping[getLocale(locale)]?.[type] ||
  localeMapping[defaultLocale][type];

export const getMaterialUILocale = (locale?: string) =>
  getLocaleInstance("material-ui", locale) as Localization;

export const getDateFnsLocale = (locale?: string) =>
  getLocaleInstance("date-fns", locale) as Locale;
