import { Button, ButtonProps, Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  button: {
    whiteSpace: "nowrap",
    [theme.breakpoints.up("md")]: {
      "&:nth-of-type(1)": {
        flexBasis: "content",
        flexGrow: 1,
        span: {
          marginLeft: "3px",
        },
      },
      "&:nth-of-type(2)": {
        flexBasis: "content",
        flexGrow: 1,
      },
    },
    "&:hover, &:focus": {
      backgroundColor: theme.colors["neutral-30"],
    },
  },
}));

export type WebCredentialCardButtonProps = Omit<
  ButtonProps,
  "disableFocusRipple"
>;

export const WebCredentialCardButton = ({
  children,
  className,
  ...props
}: WebCredentialCardButtonProps) => {
  const { classes, cx } = useStyles();

  return (
    <Button
      disableFocusRipple
      className={cx(classes.button, className)}
      color="inherit"
      {...props}
    >
      <Typography variant="textSmallRegular">{children}</Typography>
    </Button>
  );
};
