export const buildQueryString = (queryParams: Record<string, string> = {}) => {
  const searchParams = new URLSearchParams(queryParams);
  const searchParamStrings: string[] = [];
  searchParams.forEach((value, key) => {
    if (!!value) {
      searchParamStrings.push(`${key}=${value}`);
    }
  });
  return searchParamStrings.length ? `?${searchParamStrings.join("&")}` : "";
};
