import { useMemo } from "react";
import { Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import classnames from "classnames";
import {
  CopyToClipboardButton,
  LoadingSpinner,
  TimesCircleIcon,
} from "design-library";
// eslint-disable-next-line import/no-named-as-default
import QRCode from "qrcode.react";

import { useTranslate } from "i18n";

import { WebCredentialCardBase } from "./WebCredentialCardBase";
import { WebCredentialCardButton } from "./WebCredentialCardButton";

const useStyles = makeStyles()((theme) => ({
  content: {
    justifyContent: "space-between",
  },
  codeInstructions: {
    textAlign: "center",
  },
  // render separator in before element so selecting and copying the code will not include the separator
  beforeCodeChunk: {
    "&::before": {
      content: '"-"',
    },
  },
  clipboardButton: {
    "& button": {
      marginTop: theme.spacing(-0.5),
    },
  },
}));

interface WebCredentialSetupViewProps {
  onClose: () => void;
  exportCode?: string;
  isLoading?: boolean;
}

export const WebCredentialSetupView = ({
  onClose,
  exportCode,
  isLoading = false,
}: WebCredentialSetupViewProps) => {
  const { classes } = useStyles();
  const { t } = useTranslate();

  const formattedCode = useMemo(() => {
    if (exportCode) {
      const chunkSize = 3;
      const numChunks = Math.ceil(exportCode.length / chunkSize);
      return (
        <span>
          {new Array(numChunks).fill("").map((_blank, i) => (
            <span
              key={i}
              className={classnames({ [classes.beforeCodeChunk]: i !== 0 })}
            >
              {exportCode.substr(i * chunkSize, chunkSize)}
            </span>
          ))}
        </span>
      );
    }
    return <></>;
  }, [exportCode, classes]);

  return (
    <WebCredentialCardBase
      contentClassname={classes.content}
      actions={
        <WebCredentialCardButton
          startIcon={<TimesCircleIcon />}
          onClick={onClose}
        >
          {t("close")}
        </WebCredentialCardButton>
      }
      content={
        <>
          {isLoading && <LoadingSpinner text={null} />}
          {!isLoading && exportCode && (
            <>
              <Typography
                className={classes.codeInstructions}
                variant="textSmallRegular"
              >
                {t("exportCodeInstructions")}
              </Typography>
              <QRCode renderAs="svg" value={exportCode} />
              <Typography variant="textSmallRegular">
                {t("noCameraUseCode")}: {formattedCode}
                <span className={classes.clipboardButton}>
                  <CopyToClipboardButton
                    TooltipProps={{ placement: "bottom" }}
                    text={exportCode}
                  />
                </span>
              </Typography>
            </>
          )}
        </>
      }
    />
  );
};
