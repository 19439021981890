import { ReactNode } from "react";
import { Box, BoxProps, CircularProgress } from "@mui/material";
import { makeStyles } from "tss-react/mui";

export interface LoadingSpinnerProps extends BoxProps {
  /** Size variation of the spinner and text. */
  size?: "x-small" | "small" | "large";
  /**
   * Color of the spinner based on the theme.
   * Use `"light"` to display a spinner that matches the value of `theme.palette.common.white`.
   * Use`"dark"` to display a spinner that matches the value of `theme.palette.primary.main`.  */
  color?: "light" | "dark";
  /** Text shown to the right of the spinner. Set this prop to `null` or an empty string to display only the spinner. */
  text?: ReactNode | null;
}

const useStyles = makeStyles<LoadingSpinnerProps>()(
  (theme, { color, size }) => ({
    spinner: {
      margin: "x-small" ? "0.5rem" : "1rem",
      ...(color === "light" && {
        color: theme.palette.common.white,
      }),
    },
    loadingStr: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      fontSize: size === "large" ? "32px" : "18px",
    },
  })
);

/**
 * Spinner component that is used to show indeterminate progress.
 * By default, the spinner is shown with the text "Loading...".
 * This component can be customized by changing the size, color or text shown.
 */
export const LoadingSpinner = ({
  className,
  size = "small",
  text = "Loading...",
  color = "dark",
  ...rest
}: LoadingSpinnerProps) => {
  const { classes, cx } = useStyles({ color, size });

  return (
    <Box display="flex" {...rest}>
      <CircularProgress
        className={cx(classes.spinner, className)}
        color={color === "dark" ? "primary" : "inherit"}
        size={size === "x-small" ? "1rem" : "small" ? "2rem" : "4rem"}
      />
      {text && <Box className={classes.loadingStr}>{text}</Box>}
    </Box>
  );
};
