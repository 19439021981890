import { useEffect, useState } from "react";

import { useInformationTooltipContext } from "context";
import { CredentialListItem } from "types";

import { DeleteCredentialDialog } from "./DeleteCredentialDialog";
import { WebCredentialView } from "./types";
import { useExportCredential } from "./useExportCredential";
import { WebCredentialAboutView } from "./WebCredentialAboutView";
import { WebCredentialHomeView } from "./WebCredentialHomeView";
import { WebCredentialSetupView } from "./WebCredentialSetupView";

interface WebCredentialProps {
  credential: CredentialListItem;
}

export const WebCredential = ({ credential }: WebCredentialProps) => {
  const { id, infoFields } = credential;
  const [view, setView] = useState(WebCredentialView.Home);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const { setTooltip } = useInformationTooltipContext();

  const isExportEnabled = process.env.REACT_APP_BUILD_TARGET === "v0";

  const resetView = () => {
    setView(WebCredentialView.Home);
  };

  useEffect(() => {
    // reset view when the selected credential changes
    resetView();
  }, [id]);

  const { isLoading, exportCredential, exportCode, cancelExport } =
    useExportCredential({ id, resetView });

  return (
    <>
      {view === WebCredentialView.Home && (
        <WebCredentialHomeView
          {...{ credential, setView }}
          onDelete={() => setIsDeleteDialogOpen(true)}
          onAboutClick={() => {
            setView(WebCredentialView.About);
          }}
          onExport={() => {
            if (isExportEnabled) {
              setView(WebCredentialView.Setup);
              exportCredential();
            }
            //if clicked, cancel onBoardingTour
            setTooltip(null);
          }}
        />
      )}
      {view === WebCredentialView.About && (
        <WebCredentialAboutView {...{ infoFields }} onClose={resetView} />
      )}
      {view === WebCredentialView.Setup && isExportEnabled && (
        <WebCredentialSetupView
          {...{ exportCode, isLoading }}
          onClose={cancelExport}
        />
      )}
      <DeleteCredentialDialog
        closeDialog={() => setIsDeleteDialogOpen(false)}
        credentialId={id}
        open={isDeleteDialogOpen}
      />
    </>
  );
};
