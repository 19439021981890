import { useState } from "react";

import { PATHS } from "constants/paths";
import { useCoreMutation, useRedirect } from "hooks";
import { Credential } from "types";

export const useImportCredential = (redirectedFrom?: string) => {
  const { redirectToExternalURI, redirectToInternalRoute } = useRedirect();

  const [isError, setIsError] = useState(false);
  const { mutateAsync, reset, ...results } = useCoreMutation({
    mutationKey: "import",
    onSuccess: (credential?: Credential) => {
      const { handle, loginURI } = credential ?? {};

      // redirect to app login page if import was not initiated from the Web Authenticator
      if (redirectedFrom !== PATHS.INDEX && loginURI) {
        redirectToExternalURI(loginURI);

        // default to redirecting back to the Web Authenticator
      } else if (handle) {
        redirectToInternalRoute(PATHS.INDEX, {
          redirectedFrom: PATHS.IMPORT,
          id: handle,
        });
      } else {
        setIsError(true);
      }
    },
    onError: (error: Error) => {
      if (error.message !== "Aborted") {
        setIsError(true);
      }
    },
  });

  const importCredential = async (token: string) => mutateAsync([token]);

  return {
    ...results,
    importCredential,
    isError,
    reset: () => {
      reset();
      setIsError(false);
    },
  };
};
