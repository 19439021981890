import * as React from "react";

function SvgCopyIcon({
  fill = "#000",
  ...props
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      fill="none"
      height="1em"
      viewBox="0 0 20 20"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17.95 2.576L15.925.549A1.875 1.875 0 0014.598 0H7.875C6.839 0 6 .84 6 1.875V3.75H2.875C1.839 3.75 1 4.59 1 5.625v12.5C1 19.16 1.84 20 2.875 20h8.75c1.036 0 1.875-.84 1.875-1.875V16.25h3.125c1.035 0 1.875-.84 1.875-1.875V3.902c0-.498-.198-.975-.55-1.326zm-3.2-1.307a.62.62 0 01.29.164l2.027 2.027c.08.08.137.18.164.29H14.75V1.27zm-2.5 16.856c0 .345-.28.625-.625.625h-8.75a.626.626 0 01-.625-.625v-12.5c0-.345.28-.625.625-.625H6v9.375c0 1.036.84 1.875 1.875 1.875h4.375v1.875zm5-3.75c0 .345-.28.625-.625.625h-8.75a.626.626 0 01-.625-.625v-12.5c0-.345.28-.625.625-.625H13.5v2.813a.94.94 0 00.938.937h2.812v9.375z"
        fill={"currentColor"}
      />
    </svg>
  );
}

export default SvgCopyIcon;
