import { ElementType, ForwardedRef } from "react";
import { Button, ButtonProps, CircularProgress } from "@mui/material";
import { makeStyles } from "tss-react/mui";

import { ButtonUseCase } from "types";

import { ButtonColorMap } from "../constants";

export type PrimaryButtonProps<
  D extends ElementType = "button",
  // eslint-disable-next-line @typescript-eslint/ban-types
  P = {}
> = ButtonProps<D, P> & {
  component?: D;
  size?: "medium" | "large";
  useCase?: ButtonUseCase;
  forwardedRef?: ForwardedRef<D>;
  /**
   * Set to `true` when the button has extreme importance on the page.
   * Intended to be used sparingly.
   *
   * On hover or focus, an additional box shadow is shown along with the background color shift.
   * */
  critical?: boolean;
  loading?: boolean;
  loadingText?: string;
};

const useStyles = makeStyles<Pick<PrimaryButtonProps, "critical">>()(
  (theme, { critical }) => ({
    button: {
      ...(critical && {
        "&:focus": {
          boxShadow:
            "0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%), 0px 3px 5px -1px rgb(0 0 0 / 20%)",
        },
      }),
      "&:hover": {
        color: theme.palette.common.white,
      },
    },
    sizeMedium: {
      ...theme.typography.textMediumRegular,
      padding: theme.spacing(1, 2), // define padding here for higher importance
    },
    sizeLarge: {
      ...theme.typography.headerSmallRegular,
      padding: theme.spacing(1, 2), // define padding here for higher importance
    },
    main: {
      "&:hover:not([disabled]), &:focus, &.Mui-focusVisible": {
        backgroundColor: theme.palette.primary.dark,
      },
      "&.loading": {
        "&:disabled": {
          color: theme.palette.common.white,
          backgroundColor: theme.palette.primary.main,
        },
      },
    },
    loadingBuffer: {
      marginLeft: theme.spacing(1), // Spacing for the loading spinner
    },
    destructive: {
      "&:hover:not([disabled]), &:focus, &.Mui-focusVisible": {
        backgroundColor: theme.palette.secondary.dark,
      },
      "&.loading": {
        "&:disabled": {
          color: theme.palette.common.white,
          backgroundColor: theme.palette.secondary.main,
        },
      },
    },
    formInput: {
      color: theme?.colors?.["neutral-90"],
      backgroundColor: theme?.colors?.["neutral-30"],
      "&:hover, &:focus": {
        backgroundColor: theme?.colors?.["neutral-40"],
      },
      "&.loading": {
        "&:disabled": {
          color: theme?.colors?.["neutral-90"],
          backgroundColor: theme?.colors?.["neutral-30"],
        },
      },
    },
  })
);

/**
 * Primary Buttons are a combination of Use Case (Main, Destructive, Form Input) and Action Size (S or L (Only applies to Main)). They most closely correspond to the "Contained" style in Material-UI.
 * This component accepts an optional icon prop; this icon will be positioned before the button text
 */
export const PrimaryButton = <
  D extends ElementType = "button",
  // eslint-disable-next-line @typescript-eslint/ban-types
  P = {}
>({
  color,
  children,
  forwardedRef,
  critical = false,
  classes: classesProp,
  size = "medium",
  useCase = "main",
  loading = false,
  loadingText = "Loading...",
  disabled,
  ...props
}: PrimaryButtonProps<D, P>) => {
  const { classes, cx } = useStyles({ critical });

  return (
    <Button
      ref={forwardedRef as ForwardedRef<HTMLButtonElement>}
      disableFocusRipple
      className={cx(classes.button, classes[useCase], props?.className)}
      color={ButtonColorMap[useCase]}
      size={size === "medium" ? "small" : "large"} //Our "medium" font style refers to "small" in material ui sizing
      variant="contained"
      classes={{
        ...classesProp,
        containedSizeSmall: cx(
          classes.sizeMedium,
          classesProp?.containedSizeSmall
        ),
        containedSizeLarge: cx(
          classes.sizeLarge,
          classesProp?.containedSizeLarge
        ),
        disabled: cx({ loading: loading }),
      }}
      {...props}
      disabled={disabled || loading}
    >
      {loading ? (
        <>
          <CircularProgress color="inherit" size="1rem"></CircularProgress>
          <span className={classes.loadingBuffer}>{loadingText}</span>
        </>
      ) : (
        children
      )}
    </Button>
  );
};
