import {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useEffect,
} from "react";
import { useMutation } from "react-query";
import { ConsoleLog, Core, CoreBuilder } from "coresdk";

import { ErrorPage } from "components";
import { useTranslate } from "i18n";
import { mockCore } from "mocks";

export const CoreContext = createContext<{
  core?: Core;
}>({});

export const CoreContextProvider: FC<
  PropsWithChildren<Record<string, unknown>>
> = ({ children }) => {
  const { t } = useTranslate();

  const {
    mutateAsync,
    data: core,
    isError,
  } = useMutation({
    mutationKey: "build",
    mutationFn: async () => {
      if (process.env.REACT_APP_USE_MOCK_CORE) {
        return mockCore;
      }

      const builder = new CoreBuilder();
      builder.log(new ConsoleLog());

      return await builder.build();
    },
  });

  useEffect(() => {
    mutateAsync();
  }, [mutateAsync]);

  return (
    <CoreContext.Provider value={{ core }}>
      {/* display unsupported browser error when core cannot be built or initialized */}
      {isError ? (
        <ErrorPage color="textPrimary" message={t("unsupportedBrowser")} />
      ) : (
        children
      )}
    </CoreContext.Provider>
  );
};

export const useCoreContext = () => {
  const context = useContext(CoreContext);

  if (context === undefined) {
    throw new Error("CoreContext must be used within a CoreContextProvider");
  }
  return context;
};
