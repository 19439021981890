import { useState } from "react";
import { Box, Drawer, useMediaQuery } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { LoadingSpinner } from "design-library";

import { ErrorPage } from "components";
import { PATHS } from "constants/paths";
import { useRedirect } from "hooks";
import { useTranslate } from "i18n";

import { AppBar } from "./AppBar";
import { CredentialList } from "./CredentialList";
import { Toolbar } from "./Toolbar";
import { useCredentials } from "./useCredentials";
import { WebAuthenticatorContent } from "./WebAuthenticatorContent";

const useStyles = makeStyles()((theme) => ({
  container: {
    display: "flex",
    width: "100%",
    height: "100%",
    overflow: "hidden",
  },
  main: {
    flexBasis: "100%",
    flexGrow: 1,
    overflow: "hidden",
  },
  loading: {
    display: "flex",
    justifyContent: "center",
    paddingTop: theme.spacing(13),
    [theme.breakpoints.up("md")]: {
      paddingTop: theme.spacing(10),
    },
  },
  spinner: {
    // center spinner text horizontally
    marginLeft: "-1rem",
  },
  mainContent: {
    display: "flex",
    width: "100%",
    height: "100%",
  },
  drawerContainer: {
    boxSizing: "border-box",
    overflow: "hidden",
    // small mobile view
    [theme.breakpoints.down(350)]: {
      width: "calc(100% - 50px)",
    },
    // mobile view
    width: "300px",
    // desktop view
    [theme.breakpoints.up("md")]: {
      width: "433px",
    },
  },
}));

export const WebAuthenticator = () => {
  const { classes, theme } = useStyles();
  const { t } = useTranslate();
  const {
    location: { pathname },
    redirectToInternalRoute,
  } = useRedirect();
  const [isCredentialsDrawerOpen, setCredentialsDrawerOpen] = useState(false);

  const {
    isError,
    isLoading,
    credentials,
    selectedCredential,
    selectedCredentialId,
    setSelectedCredentialId,
  } = useCredentials();

  const addCredential = () => {
    if (process.env.REACT_APP_BUILD_TARGET === "v0") {
      redirectToInternalRoute(PATHS.IMPORT, {
        redirectedFrom: pathname,
      });
    }
  };

  const showDesktopView = useMediaQuery(theme.breakpoints.up("md"));

  if (isError) {
    return (
      <ErrorPage
        color="textPrimary"
        footerLinks={[]}
        message={
          <>
            <div>{t("couldNotLoadCredentials")}</div>
            <div>{t("pleaseRefresh")}</div>
          </>
        }
      />
    );
  }

  const hasCredentials = !!credentials.length;

  return (
    <Box className={classes.container}>
      <AppBar
        {...{ hasCredentials }}
        onMenuButtonClick={() =>
          setCredentialsDrawerOpen(!isCredentialsDrawerOpen)
        }
      />
      <Box className={classes.main} component="main">
        {isLoading ? (
          <>
            <Toolbar />
            <Box className={classes.loading}>
              <LoadingSpinner className={classes.spinner} size="small" />
            </Box>
          </>
        ) : (
          <Box className={classes.mainContent}>
            {!!credentials.length && (
              <Drawer
                PaperProps={{ className: classes.drawerContainer }}
                open={showDesktopView || isCredentialsDrawerOpen}
                sx={{ width: "433px", flexShrink: 0 }}
                variant={showDesktopView ? "permanent" : "temporary"}
                onClose={() => setCredentialsDrawerOpen(false)}
                {...(showDesktopView && { transitionDuration: 0 })}
              >
                <Toolbar />
                <CredentialList
                  {...{ credentials, selectedCredentialId, addCredential }}
                  selectCredential={(id) => {
                    setSelectedCredentialId(id);
                    setCredentialsDrawerOpen(false);
                  }}
                />
              </Drawer>
            )}
            <WebAuthenticatorContent
              {...{
                credentials,
                selectedCredential,
                addCredential,
              }}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};
