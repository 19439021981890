/**
 * Utility function that executes the passed-in function with the given `this` context and parameters.
 * Bypasses the TypeScript error ts(2556) by spreading the tuple of parameters as a single rest parameter.
 * TODO: move to design-library?
 */
export const callHelper = <F extends (...parameters: any[]) => any, T = any>( // eslint-disable-line @typescript-eslint/no-explicit-any
  func: F,
  thisObject: T,
  ...parameters: Parameters<F>
): ReturnType<F> => func.call(thisObject, ...parameters);
