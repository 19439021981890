import { ReactNode } from "react";
import { Box, BoxProps, Typography, TypographyProps } from "@mui/material";
import { makeStyles } from "tss-react/mui";

import { Link, LinkProps, Wordmark } from "components";
import { elevationStyles } from "styles";
import { RequireRelated } from "types";

export type FooterLink = RequireRelated<
  {
    /** Prompt text. */
    promptText?: string;
    /** Change the color of the prompt text. Default is 'textSecondary' */
    promptTextColor?: TypographyProps["color"];
    /** Link text. */
    linkText?: ReactNode;
    /** Props to pass to the Link component. */
    LinkProps?: Omit<LinkProps, "children">;
  },
  "linkText" | "LinkProps"
>;

export interface BasePageProps extends BoxProps {
  /** Customize table styling through class names. */
  classes?: Partial<
    Record<keyof ReturnType<typeof useStyles>["classes"], string>
  >;
  /** List of links to display in the footer of the page. */
  footerLinks?: FooterLink[];
}

const useStyles = makeStyles()((theme) => ({
  container: {
    fontSize: theme.typography.fontSize,
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.text.primary,
    textAlign: "center",
    padding: theme.spacing(4),
  },
  main: {
    background: theme.palette.background.default,
    ...elevationStyles["elevation-style-2"],
    borderRadius: "8px",
    maxWidth: "480px",
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(4),
    boxSizing: "border-box",
    margin: "auto",
    padding: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(4),
    },
  },
  footer: {
    padding: theme.spacing(4, 0),
  },
  footerLinksContainer: {
    marginBottom: theme.spacing(3),
  },
  footerLink: {
    margin: theme.spacing(0.5, 0),
  },
  wordmark: {
    height: 26,
    width: 200,
  },
}));

export const BasePage = ({
  children,
  classes: classesProp,
  footerLinks,
  ...props
}: BasePageProps) => {
  const { classes, cx } = useStyles();

  return (
    <Box
      {...props}
      className={cx(
        classes.container,
        classesProp?.container,
        props?.className
      )}
    >
      <Box className={cx(classes.main, classesProp?.main)} component="main">
        {children}
      </Box>
      <Box
        className={cx(classes.footer, classesProp?.footer)}
        component="footer"
      >
        {!!footerLinks?.length && (
          <Typography
            component="div"
            variant="textSmallRegular"
            className={cx(
              classes.footerLinksContainer,
              classesProp?.footerLinksContainer
            )}
          >
            {footerLinks.map(
              (
                {
                  promptText,
                  promptTextColor = "textSecondary",
                  linkText,
                  LinkProps,
                },
                i
              ) => (
                <Box
                  key={[promptText, linkText, i].join("")}
                  className={cx(classes.footerLink, classesProp?.footerLink)}
                >
                  {promptText && (
                    <Typography
                      color={promptTextColor}
                      component="span"
                      variant="textSmallRegular"
                    >
                      {promptText}
                    </Typography>
                  )}
                  {promptText && linkText && ` `}
                  {linkText && (
                    <Link variant="textSmallRegular" {...LinkProps}>
                      {linkText}
                    </Link>
                  )}
                </Box>
              )
            )}
          </Typography>
        )}
        <Wordmark className={cx(classes.wordmark, classesProp?.wordmark)} />
      </Box>
    </Box>
  );
};
