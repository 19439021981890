import { useMemo, useState } from "react";

import { PATHS } from "constants/paths";
import { useNotificationContext } from "context";
import { useOnboardingTour, useRedirect } from "hooks";
import { useGetCredentialsQuery } from "hooks/v0";
import { useListCredentialsQuery } from "hooks/v1";
import { CredentialListItem } from "types";

import { launchAppButtonId } from "./WebAuthenticatorContent";
import { setUpOtherDevicesButtonId } from "./WebCredential";

export const useCredentials = (
  version = process.env.REACT_APP_BUILD_TARGET
) => {
  const { showNotification } = useNotificationContext();
  const { showOnboardingTour } = useOnboardingTour();

  const {
    location: { state: { id, redirectedFrom } = {} },
    replaceInternalRoute,
  } = useRedirect();

  const useCredentialsQuery =
    version === "v0" ? useGetCredentialsQuery : useListCredentialsQuery;

  const {
    data: credentials = [],
    isIdle,
    isLoading,
    ...results
  } = useCredentialsQuery({
    onSuccess: async (
      credentials: CredentialListItem[],
      redirectedFromPaths: string[]
    ) => {
      // Show credential set up success notification if user was redirected from within the app
      // (e.g. registration, migration flows)
      if (
        redirectedFrom &&
        redirectedFromPaths.includes(redirectedFrom) &&
        id &&
        credentials?.some((cred) => cred.id === id)
      ) {
        setSelectedCredentialId(id);
        showNotification({
          messageKey: "credentialSuccessfullySetUp",
          severity: "success",
          onClose: () => {
            // Show the onboarding tour of information tooltips
            // if this is the first credential that the user has set up
            if (credentials.length === 1) {
              showOnboardingTour({
                appName: credentials[0].name,
                launchAppButtonId,
                setUpOtherDevicesButtonId,
              });
            }
          },
        });
        // overwrite page state so refreshing the page does not execute this flow again
        replaceInternalRoute(PATHS.INDEX);

        // else, by default, select the first credential when:
        // 1. the page is opened or
        // 2. the current credential is deleted
      } else if (credentials?.every(({ id }) => id !== selectedCredentialId)) {
        setSelectedCredentialId(credentials[0]?.id ?? null);
      }
    },
  });

  // Manage selected credential state

  const [selectedCredentialId, setSelectedCredentialId] = useState<
    string | null
  >(id || credentials[0]?.id || null);

  const selectedCredential = useMemo(
    () => credentials.find(({ id }) => id === selectedCredentialId),
    [credentials, selectedCredentialId]
  );

  return {
    credentials,
    isLoading: isIdle || isLoading,
    selectedCredential,
    selectedCredentialId,
    setSelectedCredentialId,
    ...results,
  };
};
