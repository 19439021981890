import { format } from "date-fns";

import { PATHS } from "constants/paths";
import { useCoreQuery } from "hooks/useCoreQuery";
import { getDateFnsLocale, useTranslate } from "i18n";
import { CredentialListItem, CredentialV1 } from "types";

/**
 * Hook that fetches credentials using the v1 endpoint: `listCredentials`.
 *
 * The raw data is transformed from `CredentialV1` type to
 * `CredentialListItem` type for UI purposes.
 */
export const useListCredentialsQuery = ({
  onSuccess,
}: {
  onSuccess: (
    credentials: CredentialListItem[],
    redirectedFromPaths: string[]
  ) => Promise<void>;
}) => {
  const { t } = useTranslate();

  const results = useCoreQuery({
    queryKey: "listCredentials",
    select: (creds: CredentialV1[]) =>
      creds.map((credential: CredentialV1): CredentialListItem => {
        const { id, created, identity, tenant, realm, theme, keyType } =
          credential;
        const { displayName: identityDisplayName } = identity;
        const { displayName } = realm;
        const { logoUrlLight } = theme;

        return {
          id,
          name: displayName,
          secondaryText: identityDisplayName,
          infoFields: [
            { header: t("tenantId"), value: tenant.id },
            {
              header: t("createdAt"),
              value: created
                ? format(new Date(created), "PPPp", {
                    locale: getDateFnsLocale(),
                  })
                : t("N/A"),
            },
            {
              header: t("keyType"),
              value: keyType === "webauthn" ? t("hardware") : t("software"),
            },
          ],
          // use light mode logo
          imageURL: logoUrlLight,
        };
      }),
    onSuccess: async (credentials) => onSuccess(credentials, [PATHS.BIND]),
  });

  return results;
};
