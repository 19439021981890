import { ReactNode } from "react";
import { Box, Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";

import { BasePage, BasePageProps } from "./BasePage";

export interface ErrorPageProps extends BasePageProps {
  /**
   * Determines the color of the error message, red by default.
   */
  color?: "error" | "textPrimary";
  /** Message shown in the main content. */
  message?: ReactNode;
  /** Show instructions that tell the user to try again or visit the support page. */
  details?: ReactNode;
}

const useStyles = makeStyles()((theme) => ({
  main: {
    gap: theme.spacing(3),
  },
  status: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(3),
  },
}));

export const ErrorPage = ({
  color = "error",
  message,
  details,
  children,
  classes: classesProp,
  ...rest
}: ErrorPageProps) => {
  const { classes, cx } = useStyles();

  return (
    <BasePage
      classes={{ ...classesProp, main: cx(classes.main, classesProp?.main) }}
      {...rest}
    >
      <Box aria-live="assertive" className={classes.status} role="status">
        <Typography
          {...{ color }}
          data-testid="errorpage-message"
          variant="textMediumRegular"
        >
          {message}
        </Typography>
        {details && (
          <Typography color="textPrimary" data-testid="errorpage-details">
            {details}
          </Typography>
        )}
      </Box>
      {children}
    </BasePage>
  );
};
