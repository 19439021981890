import * as React from "react";

function SvgSetUpDeviceIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 16 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2.8 1.802h8.8v1.2h1.2v-1.6a.802.802 0 00-.8-.8H2.4a.802.802 0 00-.8.8v6.4H.4a.4.4 0 00-.4.4v.4a1.603 1.603 0 001.598 1.6H8.8v-2.4h-6v-6zm12.3 2h-4.6a.9.9 0 00-.9.9v7.8a.9.9 0 00.9.9h4.6a.9.9 0 00.9-.9v-7.8a.9.9 0 00-.9-.9zm-.3 8.4h-4v-7.2h4v7.2z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgSetUpDeviceIcon;
