import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { ProgressPage } from "design-library";

import { ErrorPage } from "components";
import { useTranslate } from "i18n";

import { AuthorizationProgress } from "./types";
import { useAuthenticateCredential } from "./useAuthenticateCredential";

export const AuthorizeScreen = () => {
  const { t } = useTranslate();
  const { search } = useLocation();
  const { progress } = useAuthenticateCredential(search);

  const progressPageProps = useMemo(
    () => ({
      [AuthorizationProgress.InProgress]: {
        title: t("verifyingYourIdentity"),
        message: t("verifyingYourIdentityDetails"),
      },
      [AuthorizationProgress.Finished]: {
        title: `${t("identityVerified")} 👍`,
        message: t("identityVerifiedDetails"),
      },
    }),
    [t]
  );

  return progress !== AuthorizationProgress.Error ? (
    <ProgressPage
      {...progressPageProps[progress]}
      showLogo
      loaderPosition="bottom"
    />
  ) : (
    <ErrorPage showRetryInstructions message={t("authenticateError")} />
  );
};
