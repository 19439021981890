import * as React from "react";

function SvgIconKeyProfile(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill="#587ED0"
        d="M0 0h48v48H0z"
        className="icon-key-profile-background"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.771 19.04c0-5.36-4.346-9.707-9.706-9.707-5.361 0-9.707 4.346-9.707 9.707 0 1.387.29 2.706.815 3.9l-9.506 9.506v4.992h4.991v-2.496h2.496v-2.496h2.496l4.515-4.515a9.673 9.673 0 003.9.816c5.36 0 9.706-4.346 9.706-9.707zm-9.763-2.627a2.647 2.647 0 105.294 0 2.647 2.647 0 00-5.294 0z"
        fill="#fff"
        className="icon-key-profile-foreground"
      />
    </svg>
  );
}

export default SvgIconKeyProfile;
