/**
 * Possible error response codes defined in The OAuth 2.0 Authorization Framework
 * - https://datatracker.ietf.org/doc/html/rfc6749#section-4.1.2.1
 * */
export enum AuthorizationError {
  InvalidRequest = "invalid_request",
  UnauthorizedClient = "unauthorized_client",
  AccessDenied = "access_denied",
  UnsupportedResponseType = "unsupported_response_type",
  InvalidScope = "invalid_scope",
  ServerError = "server_error",
  TemporarilyUnavailable = "temporarily_unavailable",
}

export enum AuthorizationProgress {
  Error = "error",
  InProgress = "in_progress",
  Finished = "finished",
}
