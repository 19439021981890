import { useState } from "react";
import { Menu, MenuItem } from "@mui/material";
import { makeStyles } from "tss-react/mui";

import { useTranslate } from "i18n";

import { AboutBeyondIdentityPopup } from "./AboutBeyondIdentityPopup";
import { MenuItemLink } from "./MenuItemLink";

const useStyles = makeStyles()((theme) => ({
  menu: {
    boxShadow: "0px 1px 10px rgba(0, 0, 0, 0.15) !important", // we need to override styling that's directly on
    borderRadius: theme.shape.borderRadius,
  },
  list: {
    padding: 0,
  },
  menuItem: {
    padding: theme.spacing(1),
  },
}));

interface DesktopSettingsMenuProps {
  appInstanceId?: string;
  anchorEl: Element | null;
  handleClose: VoidFunction;
  open: boolean;
}

export const DesktopSettingsMenu = ({
  appInstanceId,
  anchorEl,
  open,
  handleClose,
}: DesktopSettingsMenuProps) => {
  const { t } = useTranslate();
  const { classes } = useStyles();
  const [isAboutPopupOpen, setAboutPopupOpen] = useState(false);

  return (
    <>
      <Menu
        {...{ open }}
        anchorEl={anchorEl}
        id="settings-button"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        classes={{
          paper: classes.menu,
          list: classes.list,
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        onClose={handleClose}
      >
        {[
          {
            children: t("about"),
            onClick: () => setAboutPopupOpen(true),
          },
          {
            children: <MenuItemLink>{t("visitSupport")}</MenuItemLink>,
          },
        ].map(({ children, onClick }, i) => (
          <MenuItem
            key={i}
            className={classes.menuItem}
            onClick={() => {
              onClick?.();
              handleClose();
            }}
          >
            {children}
          </MenuItem>
        ))}
      </Menu>
      <AboutBeyondIdentityPopup
        {...{ appInstanceId }}
        open={isAboutPopupOpen}
        onClose={() => setAboutPopupOpen(false)}
      />
    </>
  );
};
