import { ReactNode } from "react";
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Typography,
} from "@mui/material";
import { makeStyles } from "tss-react/mui";
import classnames from "classnames";
import { elevationStyles } from "design-library";

interface WebCredentialCardBaseProps {
  header?: ReactNode;
  content?: ReactNode;
  actions?: ReactNode;
  headerClassname?: string;
  contentClassname?: string;
  actionsClassname?: string;
}

export const CARD_SIZE = 311;

const useStyles = makeStyles()((theme) => ({
  card: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: theme.shape.borderRadius,
    ...elevationStyles["elevation-style-2"],
    paddingBottom: "0px",
    // mobile view:
    // dynamic with L/R margin of 32px
    // height should equal the width of the credential (square)
    width: `calc(100vw - ${theme.spacing(8)})`,
    height: `calc(100vw - ${theme.spacing(8)})`,
    // set margin top to 0 to account for mobile app bar
    margin: theme.spacing(0, 4, 4, 4),
    // desktop view: static width and height
    [theme.breakpoints.up("md")]: {
      width: CARD_SIZE,
      height: CARD_SIZE,
      margin: theme.spacing(4),
    },
    // set padding and width of header, content, actions boxes
    "& > *": {
      boxSizing: "border-box",
      width: "100%",
      padding: theme.spacing(2, 2, 0, 2),
    },
  },
  divider: {
    borderColor: theme.palette.common.black,
  },
  content: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  actions: {
    padding: 0,
    justifyContent: "space-around",
    "& button": {
      flexBasis: "100%",
      padding: theme.spacing(2),
    },
    "& > *": {
      margin: 0,
    },
  },
}));

export const WebCredentialCardBase = ({
  header,
  content,
  actions,
  headerClassname,
  contentClassname,
  actionsClassname,
}: WebCredentialCardBaseProps) => {
  const { classes } = useStyles();

  return (
    <Card className={classes.card}>
      {header && (
        <CardHeader
          className={headerClassname}
          title={
            <>
              <Typography variant="headerMediumRegular">{header}</Typography>
              <Divider className={classes.divider} />
            </>
          }
        />
      )}
      {content && (
        <CardContent className={classnames(classes.content, contentClassname)}>
          {content}
        </CardContent>
      )}
      {actions && (
        <CardActions
          disableSpacing
          className={classnames(classes.actions, actionsClassname)}
        >
          {actions}
        </CardActions>
      )}
    </Card>
  );
};
