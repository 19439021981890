import * as React from "react";

function SvgBILogo(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 1024 1024"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        className="bi-logo-background"
        d="M512 1024c282.77 0 512-229.23 512-512S794.77 0 512 0 0 229.23 0 512s229.23 512 512 512z"
        fill="#fff"
      />
      <mask
        id="bi_logo_svg__a"
        maskUnits="userSpaceOnUse"
        x={286}
        y={0}
        width={572}
        height={962}
      >
        <path
          d="M766.989 0L501.882 530.214l-125.47-250.941h-90.056l215.526 407.778L857.044 0h-90.055zM472.537 869.186v92.079h79.937V713.36l-79.937 155.826z"
          fill="#fff"
        />
      </mask>
      <g mask="url(#bi_logo_svg__a)">
        <path
          className="bi-logo-letter"
          d="M512 1024c282.77 0 512-229.23 512-512S794.77 0 512 0 0 229.23 0 512s229.23 512 512 512z"
          fill="#5077C5"
        />
      </g>
    </svg>
  );
}

export default SvgBILogo;
