import * as React from "react";

function SvgCheckCircleIcon({
  fill = "#27B066",
  ...props
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10 .813C4.65.813.312 5.15.312 10.5S4.65 20.188 10 20.188c5.35 0 9.688-4.338 9.688-9.688C19.688 5.15 15.35.812 10 .812zm0 18.125c-4.635 0-8.438-3.753-8.438-8.438 0-4.635 3.753-8.438 8.438-8.438 4.635 0 8.438 3.753 8.438 8.438 0 4.635-3.753 8.438-8.438 8.438zm5.532-10.741l-7.05 6.993a.469.469 0 01-.662-.002l-3.355-3.382a.469.469 0 01.003-.663l.332-.33a.469.469 0 01.663.002l2.695 2.717 6.384-6.334a.469.469 0 01.663.003l.33.333a.469.469 0 01-.003.663z"
        fill={fill}
      />
    </svg>
  );
}

export default SvgCheckCircleIcon;
