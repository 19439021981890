import { FC, Fragment, PropsWithChildren, ReactNode, useMemo } from "react";
import {
  Box,
  Dialog as MuiDialog,
  DialogActions,
  DialogContent,
  DialogProps as MuiDialogProps,
  DialogTitle,
  Divider,
  Typography,
} from "@mui/material";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles<
  Pick<DialogProps, "useFlexboxSpaceBetweenToStyleButtons">
>()((theme, { useFlexboxSpaceBetweenToStyleButtons }) => ({
  title: {
    padding: theme.spacing(2),
  },
  divider: {
    borderColor: theme.colors["neutral-100"],
    opacity: 0.25,
    borderBottomWidth: 1,
  },
  content: {
    ...theme.typography.textMediumRegular,
    padding: theme.spacing(2, 2),
    background: `${theme.colors["neutral-10"]}`,
  },
  footerError: {
    paddingRight: theme.spacing(1), // 8px
  },
  footer: {
    padding: theme.spacing(1), // buttonContainer is adding 8px additional padding
    display: "block",
    textAlign: "right",
    position: "sticky",
    bottom: 0,
    background: `${theme.colors["neutral-10"]}`,
  },
  dialogHeader: {
    position: "sticky",
    top: 0,
    background: `${theme.colors["neutral-10"]}`,
    zIndex: 1,
  },
  buttonContainer: {
    padding: theme.spacing(1),
    display: "flex",
    flexDirection: "row",
    justifyContent: useFlexboxSpaceBetweenToStyleButtons
      ? "space-between"
      : "flex-end",
  },
}));

export interface DialogProps extends MuiDialogProps {
  /** Title message displayed at the top of the dialog. */
  title: string;
  /** Main content rendered in the dialog, between the title and buttons. */
  contentChildren: ReactNode;
  /** Buttons displayed under the main content. */
  buttonChildren: ReactNode;
  /** Divider above the buttonChildren section */
  buttonDivider?: boolean;
  /** Require `onClose` handler, so ESC key will close the dialog. */
  onClose: Required<MuiDialogProps>["onClose"];
  /** Error displayed at the bottom of the dialog, under the buttons. */
  footerError?: string | string[];
  /** refers to Entitlements feature lock banner component */
  banner?: ReactNode;
  /**
   * Set this to true when you want the FormikDialog to override "flex-end" styling, in favour of "space-between".
   * Our main use case for this is when we want to left-align an "uninstall" button,
   * and right-align "save changes" and "cancel" button (these are grouped together).
   */
  useFlexboxSpaceBetweenToStyleButtons?: boolean;
  /** Component in which to render the contents of the dialog (e.g. its children) within. */
  WrapperComponent?: FC<PropsWithChildren<unknown>>;
  /** If a custom width is set then the maxWidth value will be automatically set to `false` so as not to be constrained */
  width?: number;
}

export const Dialog = ({
  title,
  contentChildren,
  maxWidth = "md",
  buttonChildren,
  buttonDivider = false,
  footerError,
  banner,
  useFlexboxSpaceBetweenToStyleButtons,
  WrapperComponent,
  width,
  onClose,
  ...props
}: DialogProps) => {
  const { classes } = useStyles({
    useFlexboxSpaceBetweenToStyleButtons,
  });

  // memoize to prevent the wrapper from rerendering unnecessarily
  const Wrapper = useMemo(
    () => WrapperComponent || Fragment,
    [WrapperComponent]
  );

  return (
    <MuiDialog
      aria-label={`${title} Dialog`}
      data-testid={`${title} dialog`}
      {...props}
      disableEscapeKeyDown={false}
      onClose={(event, reason) => {
        // Do not allow backdrop click to close the dialog
        if (reason === "escapeKeyDown") {
          onClose?.(event, reason);
        }
      }}
      {...{ maxWidth: width ? false : maxWidth }}
      BackdropProps={{
        // https://github.com/mui/material-ui/issues/33146
        "aria-hidden": !props.open,
      }}
      PaperProps={{
        style: width
          ? {
              width,
            }
          : {},
      }}
    >
      <Wrapper>
        <Box className={classes.dialogHeader}>
          <DialogTitle
            classes={{ root: classes.title }}
            data-testid={`${title} dialog title`}
          >
            {title}
          </DialogTitle>
          <Divider classes={{ root: classes.divider }} />
        </Box>
        {banner}
        <DialogContent
          classes={{ root: classes.content }}
          data-testid={`${title} dialog content`}
        >
          {contentChildren}
        </DialogContent>
        {buttonDivider && <Divider classes={{ root: classes.divider }} />}
        <DialogActions
          className={classes.footer}
          data-testid={`${title} dialog actions`}
        >
          <Box className={classes.buttonContainer}>{buttonChildren}</Box>
          {footerError && (
            <Typography
              classes={{ root: classes.footerError }}
              color="error"
              variant="textSmallRegular"
            >
              {footerError}
            </Typography>
          )}
        </DialogActions>
      </Wrapper>
    </MuiDialog>
  );
};
