import { UrlResponse } from "types";

/**
 * Extract the credential from the response object.
 * */
export const extractCredentialFromResponse = (res?: UrlResponse) => {
  switch (res?.type) {
    case "registration":
      return res.registration.credential;
    case "selfIssue":
      return res.selfIssue.credential;
    default:
      return undefined;
  }
};
