import { useEffect, useState } from "react";

import { useNotificationContext } from "context";
import { useCoreMutation, usePrevious } from "hooks";

export const useExportCredential = ({
  id,
  resetView,
}: {
  id: string;
  resetView: () => void;
}) => {
  const { showNotification } = useNotificationContext();

  const [exportCode, setExportCode] = useState("");
  const [isExporting, setIsExporting] = useState(false);

  const endExport = () => {
    setExportCode("");
    setIsExporting(false);
    resetView();
  };

  const { mutateAsync, isLoading, ...results } = useCoreMutation({
    mutationKey: "export",
    additionalMutationKeys: [id],
    onSuccess: (initialCode: string) => {
      setExportCode(initialCode);
      setIsExporting(true);
    },
    onError: (error: Error) => {
      endExport();

      // Do not show error notification if export was cancelled by the user
      if (error.message !== "Aborted") {
        showNotification({
          severity: "error",
          messageKey: "exportError",
        });
      }
    },
  });

  const { mutateAsync: cancel } = useCoreMutation({ mutationKey: "cancel" });

  // cancel export when the selected credential changes
  const previousId = usePrevious(id);
  useEffect(() => {
    if (previousId !== id && (isLoading || isExporting)) {
      cancel([]);
    }
  }, [previousId, id, isLoading, isExporting, cancel]);

  return {
    ...results,
    isLoading,
    isExporting,
    exportCode,
    exportCredential: async () =>
      mutateAsync([
        id,
        (exportEvent) => {
          const { type, data } = exportEvent;

          switch (type) {
            // set the token when export is started or timeout (token is refreshed)
            case "started":
            case "timeout":
              if (typeof data === "string" && data !== exportCode) {
                setExportCode(data);
              }
              break;
            case "requestReceived":
              endExport();
              break;
            default:
            // do nothing
          }
        },
      ]),
    cancelExport: async () => {
      endExport();
      return cancel([]);
    },
  };
};
