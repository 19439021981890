import { List, ListItem, Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { TimesCircleIcon } from "design-library";

import { useTranslate } from "i18n";
import { CredentialListItem } from "types";

import { WebCredentialCardBase } from "./WebCredentialCardBase";
import { WebCredentialCardButton } from "./WebCredentialCardButton";

const useStyles = makeStyles()((theme) => ({
  content: {
    overflowY: "auto",
  },
  list: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
    width: "100%",
    height: "100%",
    padding: "0px",
  },
  listItem: {
    display: "block",
    padding: 0,
  },
}));

interface WebCredentialAboutViewProps {
  infoFields: CredentialListItem["infoFields"];
  onClose: () => void;
}

export const WebCredentialAboutView = ({
  infoFields,
  onClose,
}: WebCredentialAboutViewProps) => {
  const { classes } = useStyles();
  const { t } = useTranslate();

  return (
    <WebCredentialCardBase
      contentClassname={classes.content}
      header={t("credentialInfo")}
      actions={
        <WebCredentialCardButton
          startIcon={<TimesCircleIcon />}
          onClick={onClose}
        >
          {t("close")}
        </WebCredentialCardButton>
      }
      content={
        <List className={classes.list}>
          {infoFields.map(({ header, value }) => (
            <ListItem key={header} className={classes.listItem}>
              <Typography variant="textSmallOverline">{header}</Typography>
              <Typography component="div">{value}</Typography>
            </ListItem>
          ))}
        </List>
      }
    />
  );
};
