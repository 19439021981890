import {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useState,
} from "react";

import { InformationTooltip, InformationTooltipProps } from "components";

type Tooltip = Omit<InformationTooltipProps, "open"> & {
  onAnchorElNotFound?: () => void;
};

export const InformationTooltipContext = createContext<{
  tooltip: Tooltip | null;
  showTooltip: (tooltip: Tooltip) => void;
  setTooltip: (tooltip: Tooltip | null) => void;
}>({
  tooltip: null,
  showTooltip: () => {},
  setTooltip: () => {},
  // tried exporting a function that calls setTooltip(null) but
  // android did not recognize, so we need to export it as is
});

export const InformationTooltipContextProvider: FC<
  PropsWithChildren<{
    tooltip?: Tooltip;
  }>
> = ({ children, tooltip: tooltipProp = null }) => {
  const [tooltip, setTooltip] = useState<Tooltip | null>(tooltipProp);

  const onClose: InformationTooltipProps["onClose"] = (e, reason) => {
    setTooltip(null);
    tooltip?.onClose?.(e, reason);
  };

  const showTooltip = (newTooltip: typeof tooltip) => {
    const { children, anchorElId, onAnchorElNotFound } = newTooltip || {};
    const doesElementExist =
      children || (anchorElId && document.querySelector(`#${anchorElId}`));

    if (doesElementExist) {
      setTooltip(newTooltip);
    } else {
      setTooltip(null);
      onAnchorElNotFound?.();
    }
  };

  return (
    <InformationTooltipContext.Provider
      value={{ tooltip, showTooltip, setTooltip }}
    >
      {children}
      {tooltip && (
        <InformationTooltip
          open
          autoHideDuration={5000}
          {...tooltip}
          {...{ onClose }}
        />
      )}
    </InformationTooltipContext.Provider>
  );
};

export const useInformationTooltipContext = () => {
  const context = useContext(InformationTooltipContext);

  if (context === undefined) {
    throw new Error(
      "InformationTooltipContext must be used within a InformationTooltipContextProvider"
    );
  }
  return context;
};
