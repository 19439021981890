import { Box, Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import {
  Dialog,
  DialogProps,
  LoadingSpinner,
  PrimaryButton,
  SecondaryButton,
} from "design-library";

import { useTranslate } from "i18n";

import { useDeleteCredential } from "./useDeleteCredential";

const useStyles = makeStyles()((theme) => ({
  deleteDialogContent: {
    maxWidth: "408px",
  },
  deleteDialogActions: {
    display: "flex",
    gap: theme.spacing(2),
  },
  deleteDialogActionButton: {
    textAlign: "right",
  },
  deleteSpinner: {
    position: "absolute",
    top: 0,
  },
}));

interface DeleteCredentialDialogProps
  extends Omit<
    DialogProps,
    "title" | "contentChildren" | "buttonChildren" | "onClose"
  > {
  credentialId: string;
  closeDialog: () => void;
}

export const DeleteCredentialDialog = ({
  credentialId,
  closeDialog,
  ...rest
}: DeleteCredentialDialogProps) => {
  const { classes } = useStyles();
  const { t } = useTranslate();

  const { deleteCredential, isDeleting } = useDeleteCredential({
    credentialId,
    closeDialog,
  });

  /**
   * TODO:
   * Use Dialog style for non-forms from design-library instead.
   * We use the existing <Dialog /> component as an intermediate view
   * until PDUX finishes designing a new dialog for non-forms.
   */
  return (
    <Dialog
      {...rest}
      title={t("deleteCredential")}
      buttonChildren={
        <Box className={classes.deleteDialogActions}>
          <SecondaryButton
            className={classes.deleteDialogActionButton}
            size="medium"
            useCase="main"
            onClick={closeDialog}
          >
            {t("cancel")}
          </SecondaryButton>
          <PrimaryButton
            className={classes.deleteDialogActionButton}
            disabled={isDeleting}
            size="medium"
            useCase="destructive"
            onClick={() => deleteCredential([credentialId])}
          >
            {isDeleting && (
              <LoadingSpinner
                className={classes.deleteSpinner}
                size="small"
                text=""
              />
            )}
            {t(isDeleting ? "deleting" : "delete")}
          </PrimaryButton>
        </Box>
      }
      contentChildren={
        <Typography
          className={classes.deleteDialogContent}
          color="textSecondary"
          variant="textMediumRegular"
        >
          {t("deleteCredentialMessage")}
        </Typography>
      }
      onClose={closeDialog}
    />
  );
};
