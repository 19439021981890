import { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { Location } from "history";

import { PATHS } from "constants/paths";
import { LocationState } from "types";

import { BrowserDisplayMode, useBrowserInfo } from "./useBrowserInfo";

const defaultLocation: Location<LocationState> = {
  pathname: "",
  search: "",
  state: {},
  hash: "",
};

export const useRedirect = () => {
  const history = useHistory<LocationState>();
  const {
    goBack,
    location = defaultLocation,
    push: redirectToInternalRoute,
    replace: replaceInternalRoute,
  } = history;

  const { displayMode } = useBrowserInfo();

  const redirectToExternalURI = useCallback(
    (url: string) => {
      // use the current history
      const { location, push } = history;

      if (displayMode === BrowserDisplayMode.PWA) {
        // Display the credential list view if not already shown.
        // This resets the view in the PWA after the current workflow executes,
        // so the user is not "stuck" in a pending state.
        if (location.pathname !== PATHS.INDEX) {
          push(PATHS.INDEX);
        }
        // Use `window.open` so external links open in a new tab
        // in the browser, not inside the PWA.
        window.open(url);
      } else {
        // For all other contexts, open redirects in the current tab or window.
        window.location.assign(url);
      }
    },
    [displayMode, history]
  );

  return {
    goBack,
    location,
    redirectToExternalURI,
    redirectToInternalRoute,
    replaceInternalRoute,
  };
};
