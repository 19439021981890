import { ErrorPage } from "design-library";

import { PATHS } from "constants/paths";
import { useTranslate } from "i18n";
import { convertPathToHREF } from "utilities";

export const InvalidPage = () => {
  const { t } = useTranslate();

  return (
    <ErrorPage
      color="textPrimary"
      message={t("invalidRequest")}
      footerLinks={[
        {
          promptText: t("wantToViewWebCredentials"),
          linkText: t("goToAuthenticator"),
          LinkProps: {
            href: convertPathToHREF(PATHS.INDEX),
          },
        },
      ]}
    />
  );
};
