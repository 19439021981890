import { useTranslation } from "react-i18next";
import { TOptions } from "i18next";

import { useBrowserInfo } from "hooks";

import DEFAULT_TRANSLATIONS from "./translation.json";

export type TranslationKey = keyof typeof DEFAULT_TRANSLATIONS;

export const useTranslate = () => {
  const { t, i18n } = useTranslation();
  const { browser } = useBrowserInfo();

  return {
    t: (key: TranslationKey, options?: TOptions) =>
      t(key, DEFAULT_TRANSLATIONS[key], {
        // pass global interpolation values
        browser,
        // set context to "browser" when the browser name is defined
        ...(!!browser && { context: "browser" }),
        ...options,
      }),
    i18n,
  };
};
