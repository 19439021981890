import { useQuery, UseQueryOptions } from "react-query";
import { Core } from "coresdk";

import { CoreQueryKey } from "./types";
import { useCore } from "./useCore";

export const useCoreQuery = <
  QK extends CoreQueryKey,
  D = Awaited<ReturnType<Core[QK]>>
>({
  queryKey,
  ...rest
}: {
  queryKey: QK;
} & UseQueryOptions<Awaited<ReturnType<Core[QK]>>, Error, D, QK>) => {
  const core = useCore();

  return useQuery<Awaited<ReturnType<Core[QK]>>, Error, D, QK>({
    ...rest,
    // delay query until core is ready
    enabled: !!core,
    queryKey,
    queryFn: async (): Promise<Awaited<ReturnType<Core[QK]>>> =>
      core?.[queryKey]() as Awaited<ReturnType<Core[QK]>>,
  });
};
