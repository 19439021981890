import { useCallback, useEffect, useMemo, useState } from "react";

import { useCoreMutation, useRedirect } from "hooks";
import { AuthorizationCode } from "types";
import { buildQueryString, parseErrorMessage } from "utilities";

import { AuthorizationError, AuthorizationProgress } from "./types";

/**
 * Fetches credential and executes an authentication request to the core
 * if an existing credential for the provided tenant exists.
 *
 * Example urls for mock testing:
 * - Success: http://localhost:8083/authorize?response_type=code&tenant_id=byndid-rolling&client_id=ASSIGNEDCLIENTID&scope=somescope&redirect_uri=https://google.com
 * - Error:
 *    - Missing response_type - http://localhost:8083/authorize?response_type=token&redirect_uri=https://google.com
 *    - Missing client_id - http://localhost:8083/authorize?response_type=code&redirect_uri=https://google.com
 *    - Missing redirect_uri - http://localhost:8083/authorize?response_type=code&client_id=1
 *    - Missing scope - http://localhost:8083/authorize?response_type=code&client_id=1&redirect_uri=https://google.com
 *    - Invalid code_challenge_method - http://localhost:8083/authorize?response_type=code&client_id=1&redirect_uri=https://google.com&code_challenge=12382739&scope=read&code_challenge_method=notplain
 *
 * @returns progress - the state of the authentication process
 */
export const useAuthenticateCredential = (search: Location["search"]) => {
  const { redirectToExternalURI } = useRedirect();

  const [progress, setProgress] = useState<AuthorizationProgress>(
    AuthorizationProgress.InProgress
  );

  const query = new URLSearchParams(search);
  const response_type = query.get("response_type");
  const client_id = query.get("client_id") ?? "";
  const redirect_uri = query.get("redirect_uri") ?? "";
  const state = query.get("state") ?? "";
  const code_challenge = query.get("code_challenge") ?? "";
  const code_challenge_method = query.get("code_challenge_method") ?? "";
  const scope = query.get("scope") ?? "";
  const region = query.get("region") ?? "";
  const nonce = query.get("nonce") ?? "";

  const authURL = useMemo(() => {
    let baseURL = process.env.REACT_APP_AUTHD_BASE_URL;
    // support VDC
    if (region === "eu") {
      baseURL = process.env.REACT_APP_AUTHD_EU_BASE_URL;
    }
    return `${baseURL}/v2/authorize`;
  }, [region]);

  const redirectToURIWithQuery = useCallback(
    (queryParams: Record<string, string>) => {
      const { origin, pathname, search } = new URL(redirect_uri);
      // retain previous query parameters from redirect_uri
      const oldSearchParams: Record<string, string> = {};
      new URLSearchParams(search).forEach((value, key) => {
        oldSearchParams[key] = value;
      });
      redirectToExternalURI(
        `${
          pathname === "/" ? origin : `${origin}${pathname}`
        }${buildQueryString({
          ...oldSearchParams,
          ...queryParams,
        })}`
      );
    },
    [redirectToExternalURI, redirect_uri]
  );

  const handleAuthorizationError = useCallback(
    (error?: AuthorizationError, raw_error_description = "") => {
      // Display an error view when no error or redirect_uri is provided
      if (error && redirect_uri) {
        const error_description = parseErrorMessage(raw_error_description);
        redirectToURIWithQuery({ error, error_description, state });
      } else {
        setProgress(AuthorizationProgress.Error);
      }
    },
    [redirect_uri, redirectToURIWithQuery, state]
  );

  const { mutateAsync: authenticate } = useCoreMutation({
    mutationKey: "authenticateConfidential",
    additionalMutationKeys: [
      client_id,
      redirect_uri,
      scope,
      code_challenge,
      code_challenge_method,
      nonce,
    ],
    onSuccess: (res?: AuthorizationCode) => {
      const { code } = res ?? {};
      // 6. Check that the response returns an authorization code
      if (code) {
        setProgress(AuthorizationProgress.Finished);
        // 7. Redirect to redirect_uri with state and authorization code
        redirectToURIWithQuery({ code, state });
      } else {
        handleAuthorizationError(AuthorizationError.ServerError);
      }
    },
    onError: (error: Error) => {
      // 8. Handle authorization request errors
      handleAuthorizationError(AuthorizationError.AccessDenied, error?.message);
    },
  });

  useEffect(() => {
    if (progress === AuthorizationProgress.InProgress) {
      // 1. Check that the authorization request has response_type=code
      if (response_type !== "code") {
        handleAuthorizationError(AuthorizationError.UnsupportedResponseType);
      }
      // 2. Check that a valid client_id and redirect_uri were provided
      else if (!redirect_uri || !client_id) {
        handleAuthorizationError();
      }
      // 3. Check that scope is not invalid, unknown, or malformed
      else if (!scope) {
        handleAuthorizationError(AuthorizationError.InvalidScope);
      }
      // 4. Check that optional param code_challenge_method is valid if provided
      // 5. Check that a credential for the provided tenant_id exists and authenticate only if so
      else if (
        !code_challenge_method ||
        ["S256", "plain"].includes(code_challenge_method)
      ) {
        authenticate([
          authURL,
          client_id,
          redirect_uri,
          scope,
          // handle optional params
          code_challenge
            ? {
                challenge: code_challenge,
                method: code_challenge_method ?? "plain",
              }
            : undefined,
          nonce || undefined,
        ]);
      }
      // return error if criteria above is not met or credentials could not be fetched
      else {
        handleAuthorizationError(AuthorizationError.InvalidRequest);
      }
    }
  }, [
    authenticate,
    authURL,
    client_id,
    code_challenge,
    code_challenge_method,
    handleAuthorizationError,
    progress,
    redirect_uri,
    response_type,
    scope,
    nonce,
  ]);

  return { progress };
};
