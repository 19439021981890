import { makeStyles } from "tss-react/mui";
import {
  ErrorPage as BaseErrorPage,
  ErrorPageProps as BaseErrorPageProps,
  FooterLink,
  LinkButton,
} from "design-library";

import { VISIT_SUPPORT_LINK } from "constants/links";
import { PATHS } from "constants/paths";
import { BrowserDisplayMode, useBrowserInfo } from "hooks";
import { useTranslate } from "i18n";
import { convertPathToHREF } from "utilities";

export interface ErrorPageProps extends Omit<BaseErrorPageProps, "details"> {
  /** Show instructions that tell the user to try again or visit the support page. */
  showRetryInstructions?: boolean;
  /** Optional function, executed when user clicks "Please try again." in the retry instructions.  */
  onRetry?: () => void;
}

const useStyles = makeStyles()((theme) => ({
  retryLink: {
    color: "inherit",
    textDecoration: "underline",
  },
}));

export const ErrorPage = ({
  color,
  footerLinks,
  message,
  onRetry,
  showRetryInstructions = false,
}: ErrorPageProps) => {
  const { classes } = useStyles();
  const { t } = useTranslate();

  const { displayMode } = useBrowserInfo();

  return (
    <BaseErrorPage
      color={color}
      message={message ?? t("invalidRequest")}
      {...(showRetryInstructions && {
        details: (
          <>
            <>
              {onRetry ? (
                <LinkButton className={classes.retryLink} onClick={onRetry}>
                  {t("pleaseTryAgain")}
                </LinkButton>
              ) : (
                t("pleaseTryAgain")
              )}{" "}
            </>
            {t("visitSupportError")}
          </>
        ),
      })}
      footerLinks={
        footerLinks ??
        [
          {
            promptText: t("questionOrIssues"),
            linkText: t("visitSupport"),
            LinkProps: { href: VISIT_SUPPORT_LINK, target: "_blank" },
          } as FooterLink,
        ].concat(
          displayMode === BrowserDisplayMode.PWA
            ? [
                {
                  promptText: t("wantToViewWebCredentials"),
                  linkText: t("goToAuthenticator"),
                  LinkProps: {
                    href: convertPathToHREF(PATHS.INDEX),
                  },
                } as FooterLink,
              ]
            : []
        )
      }
    />
  );
};
