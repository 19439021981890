import { Box, Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { Link, PrimaryButton } from "design-library";

import { useTranslate } from "i18n";
import { CredentialListItem } from "types";

import { TagLine } from "./TagLine";
import { Toolbar } from "./Toolbar";
import { WebCredential } from "./WebCredential";

interface WebAuthenticatorContentProps {
  credentials: CredentialListItem[];
  selectedCredential?: CredentialListItem;
  addCredential: () => void;
}

const useStyles = makeStyles()((theme) => ({
  rightPanel: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
  },
  contentPanel: {
    justifyContent: "center",
    backgroundColor: theme.palette.divider,
    overflow: "hidden",
  },
  emptyPanel: {
    backgroundColor: theme.palette.common.white,
  },
  emptyPanelContent: {
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    gap: theme.spacing(4),
    padding: theme.spacing(4),
    marginTop: theme.spacing(13),
    [theme.breakpoints.up("md")]: {
      marginTop: theme.spacing(10),
      border: `1px solid rgba(0, 0, 0, 0.25)`,
      minWidth: "365px",
    },
  },
  launchButton: {
    maxWidth: `calc(100% - ${theme.spacing(8)})`,
  },
  launchLink: {
    color: theme.palette.common.white,
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
  tagline: {
    // position absolutely at the bottom of the screen
    position: "absolute",
    bottom: "0",
    left: "50%",
    transform: "translateX(-50%)",
    width: "max-content",
    [theme.breakpoints.up("md")]: {
      // position relatively inside flexbox
      marginTop: theme.spacing(2),
      padding: 0,
      position: "relative",
      left: "unset",
      bottom: "unset",
      transform: "unset",
    },
  },
}));

export const launchAppButtonId = "web-authenticator-launch-app-button";

export const WebAuthenticatorContent = ({
  credentials,
  selectedCredential,
  addCredential,
}: WebAuthenticatorContentProps) => {
  const { t } = useTranslate();
  const { classes, cx } = useStyles();

  return (
    <Box
      className={cx(
        classes.rightPanel,
        credentials.length ? classes.contentPanel : classes.emptyPanel
      )}
    >
      <Toolbar />
      {credentials.length ? (
        <>
          {selectedCredential && (
            <>
              <WebCredential credential={selectedCredential} />
              {!!selectedCredential.launchLink && (
                <PrimaryButton
                  className={classes.launchButton}
                  size="medium"
                  useCase="main"
                >
                  <Link
                    className={classes.launchLink}
                    href={selectedCredential.launchLink}
                    id={launchAppButtonId}
                    target="_blank"
                  >
                    {t("launchApp", { name: selectedCredential.appName })}
                  </Link>
                </PrimaryButton>
              )}
            </>
          )}
        </>
      ) : (
        <Box className={classes.emptyPanelContent}>
          <Typography
            color="inherit"
            component="h1"
            variant="headerSmallRegular"
          >
            {t("thereAreNoCredentials")}
          </Typography>
          {process.env.REACT_APP_BUILD_TARGET === "v0" && (
            /* show "+ Add credential" button in both the empty and error state views */
            <PrimaryButton onClick={addCredential}>
              {t("addCredentialButton")}
            </PrimaryButton>
          )}
          <TagLine className={classes.tagline} />
        </Box>
      )}
    </Box>
  );
};
