import * as React from "react";

function SvgTimesCircleIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#TimesCircleIcon_svg__clip0)">
        <path
          d="M8.5.75A7.749 7.749 0 00.75 8.5a7.749 7.749 0 007.75 7.75 7.749 7.749 0 007.75-7.75A7.749 7.749 0 008.5.75zm0 14.5A6.75 6.75 0 011.75 8.5 6.75 6.75 0 018.5 1.75a6.75 6.75 0 016.75 6.75 6.75 6.75 0 01-6.75 6.75zm2.963-8.916L9.296 8.5l2.165 2.166a.375.375 0 010 .53l-.265.267a.375.375 0 01-.531 0L8.5 9.296l-2.166 2.165a.375.375 0 01-.53 0l-.267-.265a.375.375 0 010-.531L7.704 8.5 5.537 6.334a.375.375 0 010-.53l.266-.267a.375.375 0 01.531 0L8.5 7.704l2.166-2.166a.375.375 0 01.53 0l.267.266a.381.381 0 010 .531z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="TimesCircleIcon_svg__clip0">
          <path fill="#fff" transform="translate(.5 .5)" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgTimesCircleIcon;
