import { initReactI18next } from "react-i18next";
// eslint-disable-next-line import/no-named-as-default
import i18n from "i18next";
import Backend from "i18next-http-backend";

import { defaultLocale, getLocale, locales } from "./locales";

i18n
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // load file resources from /public/locales using a server
  .use(Backend)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    supportedLngs: locales,
    lng: getLocale(),
    fallbackLng: defaultLocale,
    debug: false,
    backend: {
      loadPath: `./locales/${getLocale()}/{{ns}}.json`,
    },
  });

export default i18n;
