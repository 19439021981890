import { ButtonProps } from "@mui/material";

export const SECRET_CHAR = "*";

export const ButtonColorMap: {
  [key: string]: ButtonProps["color"];
} = {
  main: "primary",
  destructive: "secondary",
  formInput: "primary",
};

export type ColorMode = "dark" | "light" | "auto";
