import { CredentialV1 } from "coresdk";

export enum AuthenticationProgress {
  Error = 0,
  Started = 1,
  SelectAccount = 2,
  InProgress = 3,
  Finished = 4,
}

export type SelectCredentialRef = {
  /** List of available credentials for authentication */
  credentials: {
    id: CredentialV1["id"];
    label: CredentialV1["identity"]["displayName"];
  }[];
  /** Function that resolves the promise to select a credential, given the credential's id. */
  selectCredential: (credentialId: string) => void;
  /** Function to cancel credential selection, ending the authentication flow. */
  cancel: () => void;
};
