import * as React from "react";

function SvgCloseIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M.883 12.64c-.422.421-.442 1.175.01 1.607.432.452 1.195.432 1.617.01l4.872-4.872 4.871 4.872c.432.432 1.176.442 1.607-.01.453-.432.443-1.186.01-1.617L9 7.758l4.871-4.862c.433-.442.443-1.185-.01-1.617-.431-.452-1.175-.442-1.607-.01L7.382 6.141 2.51 1.269C2.088.847 1.325.827.893 1.279.44 1.711.46 2.464.883 2.886l4.871 4.872-4.871 4.881z"
        fill="#000"
      />
    </svg>
  );
}

export default SvgCloseIcon;
