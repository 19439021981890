import { useState } from "react";
import { Box, Drawer, IconButton, Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { ChevronLeft } from "design-library";

import { useTranslate } from "i18n";

import { MenuItemLink } from "./MenuItemLink";
import { MobileSettingsMenuItem } from "./MobileSettingsMenuItem";

const useStyles = makeStyles()((theme) => ({
  drawerPaper: {
    width: "100%",
  },
  drawerHeader: {
    display: "flex",
    position: "relative",
    padding: theme.spacing(2, 3),
  },
  drawerHeaderText: {
    width: "100%",
    textAlign: "center",
  },
  drawerCloseButton: {
    position: "absolute",
    left: 12,
    top: "50%",
    transform: "translateY(-50%)",
  },
  aboutContainer: {
    textAlign: "center",
  },
}));

interface MobileSettingsMenuProps {
  appInstanceId?: string;
  handleClose: VoidFunction;
  open: boolean;
}

export const MobileSettingsMenu = ({
  appInstanceId,
  open,
  handleClose,
}: MobileSettingsMenuProps) => {
  const { t } = useTranslate();
  const { classes } = useStyles();
  const [isAboutPageOpen, setAboutPage] = useState(false);

  const version = process.env.REACT_APP_VERSION;
  const year = new Date().getFullYear();

  return (
    <>
      <Drawer
        {...{ open }}
        anchor="right"
        classes={{
          paper: classes.drawerPaper,
        }}
        onClose={handleClose}
      >
        <Box className={classes.drawerHeader}>
          <IconButton
            aria-controls="close-settings-menu"
            aria-expanded={open ? "true" : undefined}
            aria-haspopup="true"
            aria-label="close-settings-button"
            className={classes.drawerCloseButton}
            id="close-settings-menu"
            size="large"
            onClick={handleClose}
          >
            <ChevronLeft />
          </IconButton>
          <Typography
            className={classes.drawerHeaderText}
            variant="headerMediumRegular"
          >
            {t("settings")}
          </Typography>
        </Box>
        <Box>
          <MobileSettingsMenuItem
            component="button"
            onClick={() => setAboutPage(true)}
          >
            {t("about")}
          </MobileSettingsMenuItem>
          <MobileSettingsMenuItem component={MenuItemLink}>
            {t("visitSupport")}
          </MobileSettingsMenuItem>
        </Box>
      </Drawer>
      <Drawer
        anchor="right"
        open={isAboutPageOpen}
        classes={{
          paper: classes.drawerPaper,
        }}
        onClose={handleClose}
      >
        <Box className={classes.drawerHeader}>
          <IconButton
            aria-controls="close-about"
            aria-label="close-about-button"
            className={classes.drawerCloseButton}
            id="close-about"
            size="large"
            onClick={() => setAboutPage(false)}
          >
            <ChevronLeft />
          </IconButton>
          <Typography
            className={classes.drawerHeaderText}
            variant="headerMediumRegular"
          >
            {t("about")}
          </Typography>
        </Box>
        <Box className={classes.aboutContainer}>
          {/* TODO: style when we get mocks */}
          <Typography gutterBottom component="h3" variant="headerMediumRegular">
            {t("beyondIdentity")}
          </Typography>
          {appInstanceId && (
            <Typography gutterBottom>{appInstanceId}</Typography>
          )}
          <Typography gutterBottom>
            {t("aboutCopyrightNotice", { year })}
          </Typography>
          {version && (
            <Typography gutterBottom>
              {t("appVersionAndBuildNumber", { version })}
            </Typography>
          )}
        </Box>
      </Drawer>
    </>
  );
};
