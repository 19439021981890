import { makeStyles } from "tss-react/mui";
import classnames from "classnames";
import { Link, LinkProps } from "design-library";

import { VISIT_SUPPORT_LINK } from "constants/links";

const useStyles = makeStyles()((theme) => ({
  menuItemLink: {
    color: theme.palette.text.primary,
    textDecoration: "none",
    "&:hover": {
      textDecoration: "none",
    },
  },
}));

export const MenuItemLink = ({
  className,
  children,
  ...props
}: Omit<LinkProps, "href">) => {
  const { classes } = useStyles();
  return (
    <Link
      className={classnames(classes.menuItemLink, className)}
      href={VISIT_SUPPORT_LINK}
      rel="noopener noreffer nofollow"
      target="_blank"
      {...props}
    >
      {children}
    </Link>
  );
};
