import { format } from "date-fns";

import { PATHS } from "constants/paths";
import { useCoreQuery } from "hooks";
import { getDateFnsLocale, useTranslate } from "i18n";
import { Credential, CredentialListItem } from "types";

/**
 * Hook that fetches credentials using the v0 endpoint: `getCredentials`.
 *
 * The raw data is transformed from `Credential` type to
 * `CredentialListItem` type for UI purposes.
 */
export const useGetCredentialsQuery = ({
  onSuccess,
}: {
  onSuccess: (
    credentials: CredentialListItem[],
    redirectedFromPaths: string[]
  ) => Promise<void>;
}) => {
  const { t } = useTranslate();

  const results = useCoreQuery({
    queryKey: "getCredentials",
    select: (creds: Credential[]) =>
      creds.map((credential: Credential): CredentialListItem => {
        const { handle, name, imageURL, loginURI, created, keyType } =
          credential;

        return {
          id: handle,
          name,
          secondaryText: handle,
          infoFields: [
            {
              header: t("createdAt"),
              value: created
                ? format(new Date(created), "PPPp", {
                    locale: getDateFnsLocale(),
                  })
                : t("N/A"),
            },
            {
              header: t("keyType"),
              value: keyType === "webauthn" ? t("hardware") : t("software"),
            },
          ],
          imageURL,
          launchLink: loginURI,
          appName: name,
        };
      }),
    onSuccess: async (credentials) =>
      onSuccess(credentials, [PATHS.REGISTER, PATHS.IMPORT]),
  });

  return results;
};
