import * as React from "react";

function SvgAddIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M19.583 8.75H11.25V.417A.417.417 0 0010.833 0H9.167a.417.417 0 00-.417.417V8.75H.417A.417.417 0 000 9.167v1.666c0 .23.186.417.417.417H8.75v8.333c0 .23.186.417.417.417h1.666c.23 0 .417-.186.417-.417V11.25h8.333c.23 0 .417-.187.417-.417V9.167a.417.417 0 00-.417-.417z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgAddIcon;
