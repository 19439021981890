import {
  Avatar,
  Divider,
  IconButton,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  ListSubheader,
  Tooltip,
  Typography,
} from "@mui/material";
import { makeStyles } from "tss-react/mui";
import classNames from "classnames";
import { AddIcon, elevationStyles, IconKeyProfile } from "design-library";

import { useTranslate } from "i18n";
import { CredentialListItem } from "types";

import { TagLine } from "./TagLine";
import { DESKTOP_TOOLBAR_HEIGHT } from "./Toolbar";

const FOOTER_HEIGHT = 66;

const useStyles = makeStyles<void, "listItemText">()(
  (theme, _props, classes) => ({
    list: {
      display: "flex",
      flexDirection: "column",
      padding: 0,
      gap: theme.spacing(1),
      overflowY: "auto",
      height: `calc(100% - ${FOOTER_HEIGHT}px)`,
      [theme.breakpoints.up("md")]: {
        height: `calc(100% - ${DESKTOP_TOOLBAR_HEIGHT + FOOTER_HEIGHT}px)`,
      },
    },
    listHeader: {
      display: "flex",
      lineHeight: "130%",
      borderBottom: `1px solid ${theme.palette.divider}`,
      padding: theme.spacing(2, 4),
    },
    listHeaderNumCredentials: {
      width: "100%",
      fontWeight: "normal",
    },
    listHeaderButton: {
      margin: theme.spacing(-1),
      minWidth: 20,
      color: theme.palette.text.primary,
    },
    addCredentialTooltip: { maxWidth: "216px" },
    listItem: {
      flexGrow: 0,
      margin: theme.spacing(0, 1),
      borderRadius: theme.shape.borderRadius,
      width: `calc(100% - 16px)`,
      padding: theme.spacing("9.5px", 3),
      cursor: "pointer",
      "&.MuiListItemButton-root.Mui-selected": {
        backgroundColor: theme.palette.primary.main,
        [`& .${[classes.listItemText]}`]: {
          color: theme.palette.common.white,
        },
      },
      "&:hover, &:focus": {
        ...elevationStyles["elevation-style-2"],
      },
    },
    listItemAvatar: {
      minWidth: "40px",
      marginRight: theme.spacing(2),
      backgroundColor: theme.palette.common.white,
    },
    avatarFallback: {
      width: theme.spacing(5),
      height: theme.spacing(5),
    },
    listItemText: {},
    invertColors: {
      "& .icon-key-profile-foreground": {
        fill: "#587ED0",
      },
      "& .icon-key-profile-background": {
        fill: "#fff",
      },
    },
  })
);

interface CredentialListProps {
  credentials: CredentialListItem[];
  selectCredential: (id: string) => void;
  selectedCredentialId: string | null;
  addCredential: () => void;
}

export const CredentialList = ({
  credentials,
  selectCredential,
  selectedCredentialId,
  addCredential,
}: CredentialListProps) => {
  const { classes } = useStyles();
  const { t } = useTranslate();

  return (
    <>
      <List className={classes.list}>
        <ListSubheader className={classes.listHeader}>
          <Typography
            className={classes.listHeaderNumCredentials}
            color="textPrimary"
            variant="headerMediumRegular"
          >
            {t("credentialsCount", { count: credentials.length })}
          </Typography>
          {process.env.REACT_APP_BUILD_TARGET === "v0" && (
            <Tooltip
              classes={{ tooltip: classes.addCredentialTooltip }}
              placement="bottom"
              title={t("addCredentialTooltip")}
            >
              <IconButton
                aria-label={t("addCredentialTooltip")}
                className={classes.listHeaderButton}
                onClick={() => addCredential()}
              >
                <AddIcon height={20} width={20} />
              </IconButton>
            </Tooltip>
          )}
        </ListSubheader>
        {credentials.map((cred) => {
          const { id, name, secondaryText, imageURL } = cred;
          return (
            <ListItemButton
              key={`${id}-${name}-${secondaryText}`}
              disableRipple
              className={classes.listItem}
              component="li"
              selected={selectedCredentialId === id}
              onClick={() => selectCredential(id)}
            >
              <ListItemAvatar className={classes.listItemAvatar}>
                <Avatar alt={name} src={imageURL} variant="square">
                  {!imageURL && (
                    <IconKeyProfile
                      className={classNames(classes.avatarFallback, {
                        [classes.invertColors]: selectedCredentialId === id,
                      })}
                    />
                  )}
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={name}
                secondary={secondaryText}
                primaryTypographyProps={{
                  noWrap: true,
                  variant: "body1",
                  color: "textPrimary",
                  className: classes.listItemText,
                }}
                secondaryTypographyProps={{
                  noWrap: true,
                  variant: "body2",
                  color: "textSecondary",
                  className: classes.listItemText,
                }}
              />
            </ListItemButton>
          );
        })}
      </List>
      <Divider />
      <TagLine />
    </>
  );
};
