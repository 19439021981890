import "./i18n";

import React from "react";
import { createRoot } from "react-dom/client";
import reportWebVitals from "reportWebVitals";
// import * as serviceWorkerRegistration from "serviceWorkerRegistration";

function importBuildTarget() {
  switch (process.env.REACT_APP_BUILD_TARGET) {
    case "v0":
      return import("./entrypoints/v0");
    case "v1":
    default:
      return import("./entrypoints/v1");
  }
}

importBuildTarget().then(({ default: App }) =>
  createRoot(document.getElementById("root") as Element).render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  )
);

// Chromium-based browsers requires a service worker to be registered
// for the PWA to be installable.
// Register service worker only for v1.
// TODO: uncomment after service-worker.ts loading issue is fixed
// if (process.env.REACT_APP_BUILD_TARGET === "v1") {
//   serviceWorkerRegistration.register();
// }

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
