import { Link as MUILink, LinkProps as MUILinkProps } from "@mui/material";

export type LinkProps = Omit<MUILinkProps<"a">, "component"> &
  Required<Pick<MUILinkProps<"a">, "href">>;

/**
 * Link component that renders HTML `<a>`; extends `Link` from MUI.
 * This component should only be used for links that have a meaningful href.
 *
 * API:
 * - [Link API](https://mui.com/api/link/)
 * - inherits [Typography API](https://mui.com/api/typography/)
 */
export const Link = (props: LinkProps) => <MUILink {...props} />;
