/* eslint-disable tss-unused-classes/unused-classes */
import { ReactNode, SyntheticEvent, useMemo, useRef } from "react";
import {
  Box,
  Snackbar,
  SnackbarProps,
  Tooltip,
  Typography,
} from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { TertiaryButton } from "design-library";

import { useTranslate } from "i18n";

export interface InformationTooltipProps extends SnackbarProps {
  tooltipTitle: ReactNode;
  tooltipContent: ReactNode;
  anchorElId?: string;
}

const useTitleStyles = makeStyles()((theme) => ({
  title: {
    marginBottom: theme.spacing(1),
  },
  content: {
    color: theme.palette.text.primary,
    marginBottom: theme.spacing(4), // 32px
  },
  dismissButton: {
    padding: theme.spacing(0.5),
    float: "right",
    bottom: theme.spacing(2.5), // 20px
    "&:hover, &:focus": {
      textDecoration: "none",
    },
  },
}));

const useTooltipStyles = makeStyles()((theme) => ({
  tooltip: {
    background: theme.palette.common.white,
    padding: theme.spacing(2),
    maxWidth: "228px",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
    margin: theme.spacing(3, 0),
  },
  arrow: {
    color: theme.palette.common.white,
  },
}));

export const InformationTooltip = ({
  children,
  open,
  onClose,
  tooltipTitle,
  tooltipContent,
  anchorElId,
  ...props
}: InformationTooltipProps) => {
  const { classes: tooltipClasses } = useTooltipStyles();
  const { classes: titleClasses } = useTitleStyles();
  const { t } = useTranslate();

  const childrenRef = useRef(null);

  /** Element that the tooltip will render above. */
  const anchorEl = useMemo(
    () =>
      // render above custom anchor element if it exists in the DOM
      (!!anchorElId && document.querySelector(`#${anchorElId}`)) ||
      // else render above this component's children
      (children ? childrenRef.current : undefined),
    [anchorElId, children]
  );

  const title = (
    <>
      <Typography
        className={titleClasses.title}
        color="textPrimary"
        component="div"
        variant="headerSmallRegular"
      >
        {tooltipTitle}
      </Typography>
      <Typography className={titleClasses.content} variant="textMediumRegular">
        {tooltipContent}
      </Typography>
      <TertiaryButton
        className={titleClasses.dismissButton}
        useCase="main"
        onClick={(e: SyntheticEvent) => {
          onClose?.(e, "clickaway");
        }}
      >
        {t("dismiss")}
      </TertiaryButton>
    </>
  );

  return (
    <>
      <Snackbar {...{ open, onClose }} {...props}>
        <Tooltip
          arrow
          PopperProps={{ anchorEl }}
          classes={tooltipClasses}
          open={!!anchorEl && open}
          // do not show tooltip if its anchor element does not exist
          placement="top"
          {...{ title }}
        >
          <Box />
        </Tooltip>
      </Snackbar>
      {children && <div ref={childrenRef}>{children}</div>}
    </>
  );
};
