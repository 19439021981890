import { useInformationTooltipContext } from "context";
import { useTranslate } from "i18n";

/** Custom hook the returns a function `showOnBoardingTour` that will display an onboarding tour when called. */
export const useOnboardingTour = () => {
  const { t } = useTranslate();
  const { showTooltip } = useInformationTooltipContext();

  const showOnboardingTour = ({
    appName,
    launchAppButtonId,
    setUpOtherDevicesButtonId,
  }: {
    appName: string;
    launchAppButtonId: string;
    setUpOtherDevicesButtonId: string;
  }) => {
    const showSetUpOtherDevicesTooltip = () => {
      showTooltip({
        tooltipTitle: t("haveMultipleDevices"),
        tooltipContent: t("setUpOtherDevicesTooltip"),
        anchorElId: setUpOtherDevicesButtonId,
      });
    };

    // 1. Show tooltip over "Launch App" button
    showTooltip({
      tooltipTitle: t("passwordlessLoginSetUp"),
      tooltipContent: t("launchButtonTooltip", { app: appName }),
      anchorElId: launchAppButtonId,
      // 2. Show tooltip over "Set up other devices" button
      onClose: showSetUpOtherDevicesTooltip,
      onAnchorElNotFound: showSetUpOtherDevicesTooltip,
    });
  };

  return { showOnboardingTour };
};
