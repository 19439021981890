import { Box, BoxProps, Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { ChevronLeft, colors } from "design-library";

const useStyles = makeStyles()((theme) => ({
  container: {
    boxSizing: "border-box",
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    padding: theme.spacing(2),
    border: "none",
    borderBottom: `1px solid ${theme.palette.divider}`,
    cursor: "pointer",
    backgroundColor: "transparent",
  },
  typography: {
    color: theme.palette.text.primary,
    textDecoration: "none",
  },
  icon: {
    color: colors["neutral-40"],
    transform: "rotate(180deg)",
    height: "12px",
  },
}));

export const MobileSettingsMenuItem = ({ children, ...props }: BoxProps) => {
  const { classes } = useStyles();

  return (
    <Box className={classes.container} {...props}>
      <Typography
        className={classes.typography}
        component="span"
        variant="textMediumRegular"
      >
        {children}
      </Typography>
      <ChevronLeft className={classes.icon} />
    </Box>
  );
};
