import { useState } from "react";
import { IconButton, useMediaQuery } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { SettingsGearIcon } from "design-library";

import { useCoreQuery } from "hooks";

import { DesktopSettingsMenu } from "./DesktopSettingsMenu";
import { MobileSettingsMenu } from "./MobileSettingsMenu";

const useStyles = makeStyles()((theme) => ({
  button: {
    color: theme.palette.common.black,
    paddingBottom: 7, // Way to make it so we have some spacing between the menu and icon
    "& > svg": {
      paddingBottom: 5,
    },
  },
}));

export const SettingsMenu = () => {
  const { classes, theme } = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | Element>(null);
  const showDesktopView = useMediaQuery(theme.breakpoints.up("md"));

  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) =>
    // Set the anchor element to the icon rather than the button
    setAnchorEl(event.currentTarget.children[0]);

  const handleClose = () => setAnchorEl(null);

  const { data: appInstanceId } = useCoreQuery({
    queryKey: "getAppInstanceId",
  });

  return (
    <>
      <IconButton
        aria-controls="settings-menu"
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        aria-label="settings-button"
        id="settings-menu"
        size="large"
        classes={{
          root: classes.button,
        }}
        onClick={handleClick}
      >
        <SettingsGearIcon height="24px" width="24px" />
      </IconButton>
      {showDesktopView ? (
        <DesktopSettingsMenu
          {...{ anchorEl, appInstanceId, handleClose, open }}
        />
      ) : (
        <MobileSettingsMenu {...{ appInstanceId, handleClose, open }} />
      )}
    </>
  );
};
