import { Avatar, Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import {
  IconKeyProfile,
  LinkButton,
  SetUpDeviceIcon,
  TrashCanIcon,
} from "design-library";

import { useTranslate } from "i18n";
import { CredentialListItem } from "types";

import { CARD_SIZE, WebCredentialCardBase } from "./WebCredentialCardBase";
import { WebCredentialCardButton } from "./WebCredentialCardButton";

interface WebCredentialHomeViewProps {
  credential: CredentialListItem;
  onAboutClick: () => void;
  onDelete: () => void;
  onExport: () => void;
}

const useStyles = makeStyles()((theme) => ({
  content: {
    textAlign: "center",
    gap: theme.spacing(0.5),
    overflowY: "auto",
  },
  credLogo: {
    width: 60,
    height: 60,
  },
  name: {
    wordBreak: "break-all",
    overflow: "auto",
  },
  actions: {
    // show buttons via horizontal scroll
    // when screen size is too small for the expected card size
    // to prevent overlapping buttons
    [theme.breakpoints.down(CARD_SIZE + parseInt(theme.spacing(8), 10))]: {
      overflowX: "auto",
      flexDirection: "column",
      flexWrap: "wrap",
    },
  },
}));

export const setUpOtherDevicesButtonId =
  "web-authenticator-set-up-other-devices-button";

export const WebCredentialHomeView = ({
  credential,
  onAboutClick,
  onDelete,
  onExport,
}: WebCredentialHomeViewProps) => {
  const { name, secondaryText, imageURL } = credential;
  const { t } = useTranslate();
  const { classes } = useStyles();

  return (
    <WebCredentialCardBase
      actionsClassname={classes.actions}
      contentClassname={classes.content}
      actions={
        <>
          {process.env.REACT_APP_BUILD_TARGET === "v0" && (
            <WebCredentialCardButton
              id={setUpOtherDevicesButtonId}
              startIcon={<SetUpDeviceIcon />}
              onClick={onExport}
            >
              {t("setUpOtherDevices")}
            </WebCredentialCardButton>
          )}
          <WebCredentialCardButton
            startIcon={<TrashCanIcon />}
            onClick={onDelete}
          >
            {t("removeCredential")}
          </WebCredentialCardButton>
        </>
      }
      content={
        <>
          <Avatar
            alt={name}
            className={classes.credLogo}
            src={imageURL}
            variant="square"
          >
            {!imageURL && <IconKeyProfile className={classes.credLogo} />}
          </Avatar>
          <Typography
            className={classes.name}
            color="textPrimary"
            variant="headerSmallRegular"
          >
            {name}
          </Typography>
          <Typography color="textSecondary" variant="textSmallRegular">
            {secondaryText}
          </Typography>
          <LinkButton variant="textSmallRegular" onClick={onAboutClick}>
            {t("aboutThisCredential")}
          </LinkButton>
        </>
      }
    />
  );
};
