import * as React from "react";

function SvgTrashCanIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 15 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.05 13.5h.5a.25.25 0 00.25-.25v-8.5a.25.25 0 00-.25-.25h-.5a.25.25 0 00-.25.25v8.5a.25.25 0 00.25.25zm-5 0h.5a.25.25 0 00.25-.25v-8.5a.25.25 0 00-.25-.25h-.5a.25.25 0 00-.25.25v8.5a.25.25 0 00.25.25zM14.55 2H11.3L10.25.6a1.5 1.5 0 00-1.2-.6h-2.5a1.5 1.5 0 00-1.2.6L4.3 2H1.05a.25.25 0 00-.25.25v.5a.25.25 0 00.25.25h.75v11.5A1.5 1.5 0 003.3 16h9a1.5 1.5 0 001.5-1.5V3h.75a.25.25 0 00.25-.25v-.5a.25.25 0 00-.25-.25zm-8.4-.8a.503.503 0 01.4-.2h2.5a.503.503 0 01.4.2l.6.8h-4.5l.6-.8zm6.65 13.3a.5.5 0 01-.5.5h-9a.5.5 0 01-.5-.5V3h10v11.5zm-5.25-1h.5a.25.25 0 00.25-.25v-8.5a.25.25 0 00-.25-.25h-.5a.25.25 0 00-.25.25v8.5a.25.25 0 00.25.25z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgTrashCanIcon;
