import { Fragment, useRef, useState } from "react";
import { Box, Input, InputProps } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import classnames from "classnames";

interface CodeInputBoxProps {
  onImport: (code: string) => void;
}

const useStyles = makeStyles<void, "blinker">()((theme, _, classes) => ({
  codeInputBox: {
    position: "relative",
    margin: "auto",
    width: "100%",
  },
  codeInputMask: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    color: theme.palette.text.secondary,
    fontSize: 24,
    zIndex: 100,
  },
  codeInputDigit: {
    position: "relative",
    textTransform: "uppercase",
    width: "1em",
    height: "1.4em",
    borderBottomColor: theme.palette.text.secondary,
    borderBottomStyle: "solid",
    borderBottomWidth: "2px",
    "&:not(:first-of-type)": {
      marginLeft: theme.spacing(1),
    },
    "&::selection": {
      background: "transparent",
    },
  },
  codeInputHyphen: {
    width: "0.5em",
    height: "0.7em",
  },
  // eslint-disable-next-line tss-unused-classes/unused-classes
  "@keyframes blinking-cursor": {
    "0%": { opacity: 1 },
    "100%": { opacity: 0 },
  },
  blinker: {
    width: "1px",
    height: "80%",
    position: "absolute",
    top: theme.spacing(0.5),
    background: theme.palette.text.secondary,
    animationName: "$blinking-cursor",
    animationIterationCount: "infinite",
    animationTimingFunction: "steps(2, jump-none)",
    animationDuration: "1s",
  },
  codeInput: {
    width: "100%",
    height: "100%",
    position: "absolute",
    left: 0,
    top: 0,
    opacity: 0,
    // do not show cursor when input component is not focused on
    [`&:not(.Mui-focused) + * ${classes.blinker}`]: {
      display: "none",
    },
  },
}));

export const CodeInputBox = ({ onImport }: CodeInputBoxProps) => {
  const { classes } = useStyles();

  const [code, setCode] = useState("");
  const codeInputRef: InputProps["inputRef"] = useRef(null);

  const onCodeInputChange: InputProps["onChange"] = (e) => {
    const newCode = e.target.value;
    setCode(newCode);
    // use timeout to show final code before initiating import
    if (newCode.length >= 9) {
      setTimeout(() => {
        onImport(newCode.substring(0, 9).toUpperCase());
      }, 200);
    }
  };

  /** Prevent left/right arrow movement within the input */
  const onCodeInputKeyDown: InputProps["onKeyDown"] = (e) => {
    if (["ArrowLeft", "ArrowRight"].includes(e.key)) {
      e.stopPropagation();
      e.preventDefault();
    }
  };

  return (
    <Box className={classes.codeInputBox}>
      <Input
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus
        disableUnderline
        className={classes.codeInput}
        inputProps={{ maxLength: 9 }}
        // eslint-disable-next-line
        inputRef={codeInputRef}
        type="text"
        value={code}
        onChange={onCodeInputChange}
        onKeyDown={onCodeInputKeyDown}
      />
      <Box
        className={classes.codeInputMask}
        role="presentation"
        onClick={() => codeInputRef.current?.focus()}
      >
        {new Array(9).fill("").map((_blank, index) => (
          <Fragment key={index}>
            {[3, 6].includes(index) && (
              <Box
                className={classnames(
                  classes.codeInputDigit,
                  classes.codeInputHyphen
                )}
              />
            )}
            <Box key={index} className={classes.codeInputDigit}>
              {index === code.length && <div className={classes.blinker} />}
              {code.charAt(index)}
            </Box>
          </Fragment>
        ))}
      </Box>
    </Box>
  );
};
