import { useEffect, useMemo } from "react";

import { PATHS } from "constants/paths";
import { useCoreMutation, useRedirect } from "hooks";
import { sanitizeURL } from "utilities";

export const useBindCredential = (url: string) => {
  const { redirectToExternalURI, redirectToInternalRoute } = useRedirect();

  const { isInvalidRequest, requestParams } = useMemo(() => {
    const { search } = new URL(url);
    const isInvalidRequest = !search.length;
    const requestParams: [string] = [sanitizeURL(url)];

    return {
      isInvalidRequest,
      requestParams,
    };
  }, [url]);

  const {
    mutateAsync: bindCredential,
    isIdle,
    ...results
  } = useCoreMutation({
    mutationKey: "bindCredential",
    additionalMutationKeys: [...requestParams],
    onSuccess: (res) => {
      if (res) {
        const { credential, postBindRedirect } = res;
        const { id } = credential;

        // 3a. redirect to app
        if (postBindRedirect) {
          redirectToExternalURI(postBindRedirect);

          // 3b. redirect to Web Authenticator
        } else {
          redirectToInternalRoute(PATHS.INDEX, {
            redirectedFrom: PATHS.BIND,
            id,
          });
        }
      }
    },
  });

  useEffect(() => {
    if (isIdle && !isInvalidRequest) {
      bindCredential(requestParams);
    }
  }, [bindCredential, isIdle, isInvalidRequest, requestParams]);

  return { ...results, isInvalidRequest };
};
