import { Button, Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { BasePage, ProgressPage } from "design-library";

import { ErrorPage } from "components";
import { PATHS } from "constants/paths";
import { useRedirect } from "hooks";
import { useTranslate } from "i18n";

import { CodeInputBox } from "./CodeInputBox";
import { useImportCredential } from "./useImportCredential";

const useStyles = makeStyles()((theme) => ({
  title: {
    color: theme.colors["neutral-70"],
    lineHeight: "26px",
  },
  cancelButton: {
    color: theme.colors["neutral-70"],
    "&:hover, &:focus": {
      backgroundColor: theme.colors["neutral-30"],
      textDecoration: "underline",
    },
  },
}));

export const ImportCredentialScreen = () => {
  const { t } = useTranslate();
  const { classes } = useStyles();
  const {
    goBack,
    location: { state: { redirectedFrom } = {} },
  } = useRedirect();

  const { importCredential, isLoading, isError, error, reset } =
    useImportCredential(redirectedFrom);

  const showInvalidCodeError =
    error?.message?.toLowerCase().includes("invalid code") ?? false;

  // Check if mutation is occurring
  if (isLoading) {
    return <ProgressPage message={t("settingUpYourCredential")} />;
  }

  // Check if an error besides an invalid code has occurred
  if (isError && !showInvalidCodeError) {
    return (
      <ErrorPage
        showRetryInstructions
        message={t("couldNotSetupCredential")}
        onRetry={reset}
      />
    );
  }

  return (
    <BasePage>
      <Typography
        className={classes.title}
        component="h1"
        variant="headerMediumRegular"
      >
        {t("enterCode")}
      </Typography>
      <CodeInputBox onImport={importCredential} />
      {/* display inline error if the inputted code was invalid */}
      {showInvalidCodeError && (
        <Typography color="error">{t("invalidCodeError")}</Typography>
      )}
      <Typography color="textSecondary" variant="textMediumRegular">
        {t("importCredentialInstructions")}
      </Typography>
      {/* Show "Cancel" button only when redirected from the Web Authenticator */}
      {redirectedFrom === PATHS.INDEX && (
        <Button
          disableFocusRipple
          className={classes.cancelButton}
          onClick={() => goBack()}
        >
          <Typography>{t("cancel")}</Typography>
        </Button>
      )}
    </BasePage>
  );
};
