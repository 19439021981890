import { Link as MUILink, LinkProps } from "@mui/material";
import { makeStyles } from "tss-react/mui";

export type LinkButtonProps = Omit<LinkProps<"button">, "component" | "href"> &
  Required<Pick<LinkProps<"button">, "onClick">>;

const useStyles = makeStyles()((theme) => ({
  button: {
    "&:disabled": {
      color: theme.palette.text.disabled,
      cursor: "inherit",
      "&:hover": {
        textDecoration: "none",
      },
    },
  },
}));

/**
 * Link component that renders HTML `<button>`; extends `Link` from MUI.
 * This component should only be used for links that have click functionality and no meaningful href.
 *
 * API:
 * - [Link API](https://mui.com/api/link/)
 * - inherits [Typography API](https://mui.com/api/typography/)
 */
export const LinkButton = ({
  classes: buttonClasses,
  ...props
}: LinkButtonProps) => {
  const { classes, cx } = useStyles();

  return (
    <MUILink
      type="button"
      variant="textMediumRegular"
      {...props}
      component="button"
      classes={{
        ...buttonClasses,
        button: cx(classes.button, buttonClasses?.button),
      }}
    />
  );
};
