import { useEffect, useRef } from "react";
import {
  MutationKey,
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from "react-query";
import { Core } from "coresdk";

import { callHelper } from "utilities";

import { CoreMutationKey, CoreQueryKey } from "./types";
import { useCore } from "./useCore";

export const useCoreMutation = <MK extends CoreMutationKey>({
  mutationKey,
  additionalMutationKeys = [],
  queriesToInvalidate = [],
  onSuccess,
  ...rest
}: {
  mutationKey: MK;
  additionalMutationKeys?: MutationKey[];
  queriesToInvalidate?: CoreQueryKey[];
} & UseMutationOptions<
  Awaited<ReturnType<Core[MK]>>,
  Error,
  Parameters<Core[MK]>,
  (MK | MutationKey)[]
>) => {
  const core = useCore();

  const queryClient = useQueryClient();

  /** Saves the current mutation request and executes it later when `core` is ready. */
  const mutateWithCore = useRef<(core: Core) => Promise<void> | undefined>();

  const { mutate, mutateAsync, ...results } = useMutation({
    ...rest,
    mutationKey: [mutationKey, ...additionalMutationKeys],
    mutationFn: async (
      variables: Parameters<Core[MK]>
    ): Promise<Awaited<ReturnType<Core[MK]>>> => {
      const baseMutate = (currentCore: Core): ReturnType<Core[MK]> =>
        callHelper(currentCore[mutationKey as MK], currentCore, ...variables);

      if (core) {
        return (await baseMutate(core)) as Awaited<ReturnType<Core[MK]>>;
      }

      // saves the current mutation request for execution once core is ready
      // replaces the previous mutation request if any
      return new Promise<Awaited<ReturnType<Core[MK]>>>((resolve, reject) => {
        mutateWithCore.current = async (currentCore: Core) => {
          try {
            resolve(
              (await baseMutate(currentCore)) as Awaited<ReturnType<Core[MK]>>
            );
          } catch (err) {
            reject(err);
          }
        };
      });
    },
    onSuccess: (
      data: Awaited<ReturnType<Core[MK]>>,
      variables: Parameters<Core[MK]>,
      context?: (MK | MutationKey)[]
    ) => {
      onSuccess?.(data, variables, context);

      if (queriesToInvalidate.length) {
        queryClient.invalidateQueries(queriesToInvalidate);
      }
    },
  });

  useEffect(() => {
    // execute mutateWithCore mutation once core is ready
    if (core && mutateWithCore.current) {
      mutateWithCore.current(core);
      mutateWithCore.current = undefined;
    }
  }, [core]);

  return {
    // omit `mutate` from the returned object to prevent its usage
    ...results,
    mutateAsync,
  };
};
