import { useEffect, useRef } from "react";

/** Custom hook that returns the previous value of a variable (from the previous render cycle). */
export const usePrevious = <V>(value: V) => {
  const valueRef = useRef<V | undefined>();

  useEffect(() => {
    valueRef.current = value;
  }, [value]);

  return valueRef.current;
};
