import { Box, LinearProgress, Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";

import { BILogo } from "components";
import { borders } from "styles";

import { BasePage, BasePageProps } from "./BasePage";

const DEFAULT_ELEMENT_IDS: ProgressPageProps["ids"] = {
  loader: "progress-page-loader",
  title: "progress-page-title",
  message: "progress-page-message",
};

export interface ProgressPageProps extends BasePageProps {
  /** Show or hide the loading progress bar. By default, it is shown. */
  loading?: boolean;
  /** Main header text displayed below the loading progress bar.  */
  title?: string;
  /** Additional text displayed below the title text. */
  message?: string;
  /** Customize the HTML id of elements on the page. */
  ids?: {
    loader?: string;
    title?: string;
    message?: string;
  };
  /** Customize the aria-label of elements on the page. */
  ariaLabels?: {
    loader?: string;
    title?: string;
    message?: string;
  };
  /** Display the Beyond Identity logo above the content */
  showLogo?: boolean;
  /** Allow the loading bar to be positioned beneath the title/message, above (ie: top) by default */
  loaderPosition?: "top" | "bottom";
}

const useStyles = makeStyles()((theme) => ({
  logo: {
    overflow: "hidden",
    borderRadius: theme.spacing(2),
    boxShadow: `0px 0px ${theme.spacing(1)} ${
      theme.colors["neutral-100-alpha"]
    }`,
    border: borders["border-style-1"],
    display: "inline-block",
    height: theme.spacing(9),
    width: theme.spacing(9),
  },
  main: {
    gap: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      gap: theme.spacing(4),
    },
  },
  textContainer: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
  },
  progress: {
    width: "100%",
  },
}));

export const ProgressPage = ({
  loading = true,
  title,
  message,
  classes: classesProp,
  children,
  ids,
  ariaLabels,
  showLogo = true,
  loaderPosition = "bottom",
  ...rest
}: ProgressPageProps) => {
  const { classes, cx } = useStyles();

  const elementIDs = {
    ...DEFAULT_ELEMENT_IDS,
    ...ids,
  };

  const ProgressBar = () => {
    return (
      <LinearProgress
        aria-live="assertive"
        className={classes.progress}
        id={elementIDs.loader}
        {...(ariaLabels?.loader || !title
          ? { "aria-label": ariaLabels?.loader || "Loading..." }
          : { "aria-labelledby": elementIDs.title })}
      />
    );
  };

  return (
    <BasePage
      {...rest}
      classes={{ ...classesProp, main: cx(classes.main, classesProp?.main) }}
    >
      {loading && loaderPosition === "top" && <ProgressBar />}
      {!!(title || message || showLogo) && (
        // Set role="status" to ensure that all content inside this element
        // is read out loud by the screen reader on change.
        // Meets success criterion 4.1.3 Status Messages.
        <Box
          aria-live="assertive"
          className={classes.textContainer}
          role="status"
        >
          {showLogo && (
            <Box>
              <BILogo className={classes.logo} />
            </Box>
          )}
          {title && (
            <Typography
              aria-label={ariaLabels?.title}
              color="textPrimary"
              component="h1"
              id={elementIDs.title}
              variant="headerMediumRegular"
            >
              {title}
            </Typography>
          )}
          {message && (
            <Typography
              aria-label={ariaLabels?.message}
              color="textPrimary"
              id={elementIDs.message}
              variant="textMediumRegular"
            >
              {message}
            </Typography>
          )}
        </Box>
      )}
      {loading && loaderPosition === "bottom" && <ProgressBar />}
      {children}
    </BasePage>
  );
};
