import { ComponentProps } from "react";
import { Box, Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import classnames from "classnames";
import { Link } from "design-library";

import { useTranslate } from "i18n";

const useStyles = makeStyles()((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(2, 4), // 16px 32px
  },
}));

export const TagLine = (props: ComponentProps<typeof Box>) => {
  const { t } = useTranslate();
  const { classes } = useStyles();

  return (
    <Box {...props} className={classnames(classes.container, props?.className)}>
      <Typography variant="textSmallRegular">
        {t("getBeyondIdentity")}
      </Typography>
      <Link
        href={`${process.env.REACT_APP_KEYMAKER_BASE_URL}/downloads`}
        target="_blank"
      >
        <Typography variant="textSmallRegular">
          {t("viewAvailablePlatforms")}
        </Typography>
      </Link>
    </Box>
  );
};
