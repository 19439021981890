import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { PATHS } from "constants/paths";
import { useCoreMutation, useRedirect } from "hooks";
import { LocationState, UrlResponse } from "types";

import { extractCredentialFromResponse } from "./extractCredentialFromResponse";

export const useRegisterCredential = () => {
  const { redirectToExternalURI, redirectToInternalRoute } = useRedirect();

  const { search } = useLocation<LocationState>();
  const query = new URLSearchParams(search);
  const updatesURI = query.get("updates_uri") ?? "";

  const [isError, setIsError] = useState(!updatesURI);
  const { mutateAsync: registerCredential, ...results } = useCoreMutation({
    mutationKey: "register",
    additionalMutationKeys: [updatesURI],
    onSuccess: (res?: UrlResponse) => {
      const { handle, loginURI } = extractCredentialFromResponse(res) ?? {};

      // redirect to app
      if (loginURI) {
        redirectToExternalURI(loginURI);

        // redirect to Web Authenticator
      } else if (handle) {
        redirectToInternalRoute(PATHS.INDEX, {
          redirectedFrom: PATHS.REGISTER,
          id: handle,
        });
      } else {
        setIsError(true);
      }
    },
    onError: () => setIsError(true),
  });

  useEffect(() => {
    if (updatesURI) {
      // pass in the entire url to core.register
      registerCredential([window.location.href]);
    }
  }, [updatesURI, registerCredential]);

  return { ...results, updatesURI, isError };
};
